import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./logisticsModal.css";
class LogisticsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
    };
  }

  render() {
    const { currentUser, orderTrackingResult, booking } = this.props;
    let parcelObj = {};
    if (orderTrackingResult) {
      parcelObj = orderTrackingResult.parcelsArray[0];
    }
    console.log(currentUser);
    if (booking && !booking.trackingNo) {
      return (
        <>
          <div
            className={
              this.props.toggleLogisticsModal
                ? "modal fade show"
                : "modal fade show visible-modal"
            }
            id="request_payment_popup"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered request_popup"
              role="document"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <div
                className="modal-content final-payment-modal"
                style={{
                  backgroundColor: "white",
                  overflowY: "scroll",
                  maxHeight: 600,
                }}
              >
                <div className="modal-body p-0">
                  <section
                    className="pos-rel bg-light-gray"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="container-fluid make-payment-container">
                      <a
                        id="modal-close-icon-payment-modal-3"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          this.props.startToggleLogisticsModal(null);
                        }}
                      >
                        <i
                          className="icofont-close-line"
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            paddingRight: 5,
                          }}
                        />
                      </a>
                      <div className="d-lg-flex justify-content-center no-gutters mb-spacer-md">
                        Your order has not been shipped by the supplier yet.
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className={
              this.props.toggleLogisticsModal
                ? "modal fade show"
                : "modal fade show visible-modal"
            }
            id="request_payment_popup"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered request_popup"
              role="document"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <div
                className="modal-content final-payment-modal"
                style={{
                  backgroundColor: "white",
                  overflowY: "scroll",
                  maxHeight: 700,
                }}
              >
                <div className="modal-body p-0">
                  <section
                    className="pos-rel bg-light-gray"
                    style={{ paddingTop: 0 }}
                  >
                    <div
                      className="container-fluid make-payment-container"
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      <a
                        id="modal-close-icon-payment-modal-3"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          this.props.startToggleLogisticsModal(null);
                        }}
                      >
                        <i
                          className="icofont-close-line"
                          style={{
                            color: "gray",
                            fontWeight: "bolder",
                            paddingRight: 5,
                          }}
                        />
                      </a>

                      {orderTrackingResult ? (
                        <>
                          <div className="row tracking-no-header-1">
                            Tracking No : {parcelObj.trackingNo}
                          </div>
                          <div
                            className="section-containing-container"
                            style={{ borderTop: "2px solid gainsboro" }}
                          >
                            <div
                              className="flex-options"
                              style={{ marginTop: 20, padding: 5 }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  padding: 3,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#d6ebf7",
                                    padding: 20,
                                    border: "2px dashed #9edcff",
                                  }}
                                >
                                  <table className="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td
                                          colSpan="2"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Lot No
                                        </td>
                                        <td>
                                          {orderTrackingResult.lotArray
                                            .length == 1
                                            ? orderTrackingResult.lotObj.lotNo
                                            : orderTrackingResult.lotArray.map(
                                                (lot) => `${lot},`
                                              )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">Country</td>
                                        <td>
                                          {" "}
                                          {
                                            orderTrackingResult.lotObj
                                              .selectCountry
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">Shipment Method</td>
                                        <td>
                                          {" "}
                                          {
                                            orderTrackingResult.lotObj
                                              .shipmentMethod
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">Shipping Line</td>
                                        <td>
                                          {
                                            orderTrackingResult.lotObj
                                              .shippingLine
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">Carton No</td>
                                        <td>
                                          {orderTrackingResult.parcelsArray.map(
                                            (parcel) => `${parcel.parcelId},`
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">
                                          Delivery Date (approx)
                                        </td>
                                        <td>
                                          {
                                            orderTrackingResult.lotObj
                                              .arrivalDate
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    style={{
                                      fontSize: 13,
                                      marginBottom: 20,
                                      marginTop: 10,
                                    }}
                                  >
                                    * উপরোল্লেখিত ডেলিভারি তারিখ একটি সম্ভাব্য
                                    তারিখ। এয়ারপোর্ট, কাস্টমজনিত সুবিধা অসুবিধার
                                    কারণে পণ্য ডেলিভারি পেতে কিছুসময় দেরী অথবা
                                    দ্রুত পাওয়া যেতে পারে। অতিরিক্ত দেরী হলে
                                    আমাদের কাস্টমার সার্ভিসতে যোগাযোগ করুন ।
                                  </div>
                                  <div style={{ fontSize: 13 }}>
                                    *আপনার প্রেরিত পণ্যের প্রতিটা বক্সের উপরে
                                    আপনার শিপিং মার্ক/নাম, পণ্যের বিবরণ (পণ্যের
                                    নাম, কত পিস) অবশ্যই অবশ্যই লেখা থাকতে হবে।
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex-options"
                              style={{ marginTop: 20, padding: 5 }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  border: "2px dashed gray",
                                }}
                              >
                                <div>
                                  <div className="px-3 m-4">
                                    <div className="row mt-3" />
                                    <div className="row mt-4">
                                      <div className="col order-tracking-bar">
                                        {orderTrackingResult.lotObj
                                          .shipmentStatusScore >= 1 ? (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i
                                                className="icofont-industries"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                  color: "white",
                                                  border:
                                                    "1px soild rgb(1, 70, 109)",
                                                }}
                                              />
                                              <div
                                                className="progress-bar-line"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                }}
                                              />
                                            </div>
                                            &nbsp;
                                            <p
                                              style={{
                                                color: "rgb(1, 70, 109)",
                                              }}
                                            >
                                              Abroad Warehouse <br />
                                              {
                                                orderTrackingResult.lotObj
                                                  .abroadWarehouseDate
                                              }
                                            </p>
                                          </span>
                                        ) : (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i className="icofont-industries" />
                                              <div className="progress-bar-line" />
                                            </div>
                                            &nbsp; <p>Abroad Warehouse</p>
                                          </span>
                                        )}
                                        {orderTrackingResult.lotObj
                                          .shipmentStatusScore >= 2 ? (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i
                                                className="icofont-police"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                  color: "white",
                                                  border:
                                                    "1px soild rgb(1, 70, 109)",
                                                }}
                                              />
                                              <div
                                                className="progress-bar-line"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                }}
                                              />
                                            </div>
                                            &nbsp;
                                            <p
                                              style={{
                                                color: "rgb(1, 70, 109)",
                                              }}
                                            >
                                              Abroad Customs
                                              <br />
                                              {
                                                orderTrackingResult.lotObj
                                                  .abroadCustomsDate
                                              }
                                            </p>
                                          </span>
                                        ) : (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i className="icofont-police" />
                                              <div className="progress-bar-line" />
                                            </div>
                                            &nbsp; <p>Abroad Customs</p>
                                          </span>
                                        )}
                                        {orderTrackingResult.lotObj
                                          .shipmentStatusScore >= 3 ? (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i
                                                className="icofont-airplane-alt"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                  color: "white",
                                                  border:
                                                    "1px soild rgb(1, 70, 109)",
                                                }}
                                              />
                                              <div
                                                className="progress-bar-line"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                }}
                                              />
                                            </div>
                                            &nbsp;
                                            <p
                                              style={{
                                                color: "rgb(1, 70, 109)",
                                              }}
                                            >
                                              Ready to Fly
                                              <br />
                                              {
                                                orderTrackingResult.lotObj
                                                  .readyToFlyDate
                                              }
                                            </p>
                                          </span>
                                        ) : (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i className="icofont-airplane-alt" />
                                              <div className="progress-bar-line" />
                                            </div>
                                            &nbsp; <p>Ready to Fly</p>
                                          </span>
                                        )}
                                        {orderTrackingResult.lotObj
                                          .shipmentStatusScore >= 4 ? (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i
                                                className="icofont-police"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                  color: "white",
                                                  border:
                                                    "1px soild rgb(1, 70, 109)",
                                                }}
                                              />
                                              <div
                                                className="progress-bar-line"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                }}
                                              />
                                            </div>
                                            &nbsp;
                                            <p
                                              style={{
                                                color: "rgb(1, 70, 109)",
                                              }}
                                            >
                                              Bangladesh Customs
                                              <br />
                                              {
                                                orderTrackingResult.lotObj
                                                  .bangladeshCustomsDate
                                              }
                                            </p>
                                          </span>
                                        ) : (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i className="icofont-police" />
                                              <div className="progress-bar-line" />
                                            </div>
                                            &nbsp; <p>Bangladesh Customs</p>
                                          </span>
                                        )}
                                        {orderTrackingResult.lotObj
                                          .shipmentStatusScore >= 5 ? (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i
                                                className="icofont-home"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                  color: "white",
                                                  border:
                                                    "1px soild rgb(1, 70, 109)",
                                                }}
                                              />
                                              <div
                                                className="progress-bar-line"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                }}
                                              />
                                            </div>
                                            &nbsp;
                                            <p
                                              style={{
                                                color: "rgb(1, 70, 109)",
                                              }}
                                            >
                                              Custom released
                                              <br />
                                              {
                                                orderTrackingResult.lotObj
                                                  .localWarehouseDate
                                              }
                                            </p>
                                          </span>
                                        ) : (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i className="icofont-home" />
                                              <div className="progress-bar-line" />
                                            </div>
                                            &nbsp; <p>Custom released</p>
                                          </span>
                                        )}
                                        {parcelObj && parcelObj.parcelStatus ? (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i
                                                className="icofont-tick-mark"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(1, 70, 109)",
                                                  color: "white",
                                                  border:
                                                    "1px soild rgb(1, 70, 109)",
                                                }}
                                              />
                                            </div>
                                            &nbsp;
                                            <p
                                              style={{
                                                color: "rgb(1, 70, 109)",
                                              }}
                                            >
                                              Paicart warehouse
                                            </p>
                                          </span>
                                        ) : (
                                          <span className="row">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <i className="icofont-tick-mark" />
                                            </div>
                                            &nbsp; <p>Paicart warehouse</p>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {false ? (
                                    <>
                                      <div className="row mt-3">
                                        <div className="col">
                                          <div
                                            className="center-head"
                                            style={{ marginBottom: "0px" }}
                                          >
                                            <span
                                              className="bg-light-gray"
                                              style={{
                                                textTransform: "none",
                                                color: "purple",
                                              }}
                                            >
                                              শিপমেন্ট সংক্রান্ত নোটিশ
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          color: "gray",
                                          fontSize: "80%",
                                          padding: "10px",
                                          paddingTop: "5px",
                                          paddingBottom: "15px",
                                        }}
                                      >
                                        Notice
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row tracking-no-header-1">
                            Tracking No : {booking && booking.trackingNo}
                          </div>

                          <div
                            className="section-containing-container"
                            style={{
                              borderTop: "2px solid white",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                marginTop: 20,
                                color: "white",
                                fontWeight: "bold",

                                padding: 10,
                                fontSize: 15,
                              }}
                            >
                              <div>Your order is shipped by the supplier.</div>
                              <div>
                                Soon it will be received in our china warehouse.
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  orderTrackingResult: state.orders.orderTrackingResult,
});

export default connect(
  mapStateToProps,
  null
)(LogisticsModal);
