import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import custom Components
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice1688 from "./common/product/details-price-1688";
import BuyAndShipForMe from "../layouts/homePage/buyAndShipForMe.png";
import DetailsTopTabsApi from "./common/details-top-tabs-api";
import {
  addToCart,
  addToCartUnsafe,
  addToWishlist,
  fetchSingleProduct,
  getAllProductsFirestore,
  setSearchedProductsArray,
} from "../../actions";
import { batchGetItemFullInfo } from "../../actions/get1688Data";
import ImageZoom from "./common/product/image-zoom";
import SmallImages from "./common/product/small-image";
import {
  auth,
  getSingleProduct,
  addCartItemTofirestore,
  addWishlistTofirestore,
  addCartItemsToOrdersFirestore,
  getAllFirestoreAliProductsList,
} from "../../firebase/firebase.utils";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "./singleProduct.css";
import RelatedProduct from "./common/product/relatedProduct";
import ReactImageMagnify from "react-image-magnify";
import ReactPlayer from "react-player";
import "./no-sidebar.css";
class NoSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: this.slider1,
      colorVariantImage: "",
      item: null,
      searchBarValue: "",
    };
  }

  componentDidMount = async () => {
    const aliProductsArray = await getAllFirestoreAliProductsList();
    this.props.getAllProductsFirestore([...aliProductsArray]);
    let item = await batchGetItemFullInfo(this.props.match.params.id);
    this.setState({
      nav1: this.slider1,
      item: item,
    });
  };

  componentWillReceiveProps = async (nextProps) => {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({
        item: null,
      });
      let item = await batchGetItemFullInfo(nextProps.match.params.id);
      this.setState({
        item: item,
      });
    }
  };

  addToReduxAndFirestoreCart = async (product) => {
    const { addToCart, currentUser, currency } = this.props;
    let taka = 0;
    if (currency.taka) {
      taka = currency.taka;
    }
    console.log("add to redux and firestore cart is getting called");
    console.log(product);
    await addToCart(currentUser, { ...product, currency: taka });
    toast.success("Added to Cart");
  };
  addToReduxAndFirestoreWishlist = async (product) => {
    const { addToWishlist, currentUser } = this.props;
    console.log(this.props);
    if (currentUser && currentUser.uid) {
      await addWishlistTofirestore(currentUser, product);
      toast.success("Item addded to wishilist");
    } else {
      alert("You must login first.");
    }
  };

  clickOnColorVariant = (imgUrl) => {
    this.setState({ colorVariantImage: imgUrl });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSearchBarSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchBarValue.length < 35) {
      var win = window.open(
        `${process.env.PUBLIC_URL}/collection/${this.state.searchBarValue}`,
        "_blank"
      );
      win.focus();
    } else {
      this.props.setSearchedProductsArray(0, []);
      if (this.state.searchBarValue.includes("1688")) {
        let productId = this.state.searchBarValue.split("/")[4].split(".")[0];
        console.log(productId);

        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${productId}`,
          "_blank"
        );
        win.focus();
      } else {
        let taobaoUrl = new URL(this.state.searchBarValue);
        let params = taobaoUrl.search;
        const urlParams = new URLSearchParams(params);
        const id = urlParams.get("id");
        var win = window.open(
          `${process.env.PUBLIC_URL}/product/${id}`,
          "_blank"
        );
        win.focus();
      }
    }
    this.setState({ searchBarValue: "" });
  };

  render() {
    const {
      symbol,

      addToCart,
      addToCartUnsafe,
      addToWishlist,
    } = this.props;
    const { item } = this.state;

    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true,
    };

    console.log(window.innerWidth);

    return (
      <div>
        <Helmet>
          <title>{item ? item.name : this.props.match.params.id}</title>
        </Helmet>
        <ToastContainer />
        {window.innerWidth > 600 ? (
          item ? (
            <Breadcrumb title={item.name} />
          ) : (
            ""
          )
        ) : null}
        {/*Section Start*/}
        {item ? (
          <>
            <div
              className="top-searchbar-parent webview-hidden"
              style={{ background: "white" }}
            >
              <div className="top-searchbar-container-2">
                <form
                  className="form_search top-form form-search2"
                  role="form"
                  onSubmit={this.handleSearchBarSubmit}
                  style={{
                    borderRadius: "0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    id="query search-autocomplete"
                    type="search"
                    placeholder="Search 100+ millions of products"
                    value={this.state.searchBarValue}
                    onChange={this.handleChange}
                    name="searchBarValue"
                    className="nav-search nav-search-field"
                    aria-expanded="true"
                    style={{ color: "black" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      onClick={this.handleImgChange}
                      className="btn-search"
                      style={{
                        width: "40px",
                        position: "relative",
                      }}
                    >
                      <i
                        className="fa fa-camera"
                        style={{
                          color: "#ff4c3b",
                          marginTop: 10,
                          fontSize: "150%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <button
                      type="submit"
                      style={{}}
                      onClick={this.handleSearchBarSubmit}
                      className="search-submit-button"
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <section
              className="product-container-section"
              style={{
                margin: "auto",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div className="collection-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 product-thumbnail">
                      {this.state.colorVariantImage ? (
                        <ImageZoom image={this.state.colorVariantImage} />
                      ) : item.videos && item.videos.length > 0 ? (
                        <div
                          className="image-container"
                          style={{
                            border: "2px solid gainsboro",
                            borderRadius: 5,
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          <ReactPlayer
                            url={item.videos[0].Url}
                            height={"100%"}
                            width={"100%"}
                            light={
                              <img
                                src={item.videos[0].PreviewUrl}
                                height={"100%"}
                                width={"100%"}
                              />
                            }
                            controls={true}
                            playing={true}
                          />
                        </div>
                      ) : (
                        <>
                          <ImageZoom
                            image={
                              item.pictures.length > 0 ? item.pictures[0] : ""
                            }
                          />
                        </>
                      )}
                      <SmallImages
                        item={item}
                        navOne={this.state.nav1}
                        clickOnColorVariant={this.clickOnColorVariant}
                      />
                      <div style={{ marginTop: 30, marginLeft: 10 }}>
                        <p className="service-list">
                          <span>&#10003;</span> 24/7 online services for our
                          customers via wechat,whatsapp, hotline and facebook.{" "}
                          <br />
                          <span>&#10003;</span> The best wholesale prices and a
                          vast variety of goods.
                          <br />
                          <span>&#10003;</span> live tracking your product 24/7.
                          <br />
                          <span>&#10003;</span> 100% Secure payment with us.{" "}
                          <br />
                          <span>&#10003;</span> Fastest product delivery(around
                          15-20 working days). <br />
                          <span>&#10003;</span> Cheapest shipping charges around
                          the country.
                          <br />
                          <span>&#10003;</span> Claiming 100% refund facility.
                          <Link
                            to={`${process.env.PUBLIC_URL}/pages/refund-policy`}
                            style={{ fontSize: "80%", color: "orange" }}
                          >
                            see refund policy
                          </Link>
                          <br />
                        </p>
                      </div>
                      <div>
                        <div style={{ fontWeight: "bold", marginTop: 20 }}>
                          How buy and ship for me works
                        </div>
                        <div>
                          <img
                            src={BuyAndShipForMe}
                            style={{
                              width: "100%",
                              borderRadius: 10,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <DetailsWithPrice1688
                      {...this.props}
                      symbol={symbol}
                      item={item}
                      navOne={this.state.nav1}
                      clickOnColorVariant={this.clickOnColorVariant}
                      addToCartClicked={this.addToReduxAndFirestoreCart}
                      BuynowClicked={addToCartUnsafe}
                      addToWishlistClicked={this.addToReduxAndFirestoreWishlist}
                    />
                  </div>
                </div>
              </div>
            </section>

            {/*Section End*/}

            {item.relatedGroups && item.relatedGroups.length > 0 && (
              <section className="tab-product m-0">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-lg-12">
                      <RelatedProduct
                        type={"others"}
                        status={"in-stock"}
                        title="Similar Products"
                        subtitle="Suppliers"
                        item={item}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className="tab-product m-0">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    <DetailsTopTabsApi item={item} />
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <div
            className="loader-wrapper"
            style={{
              display: "block",
              left: "0",
              height: "80vh",
              bottom: "0",
              top: "20vh",
            }}
          >
            <div className="loader" color="#18768f" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    symbol: state.data.symbol,
    currentUser: state.user.currentUser,
    currency: state.user.currency,
  };
};

export default connect(
  mapStateToProps,
  {
    addToCart,
    addToCartUnsafe,
    addToWishlist,
    fetchSingleProduct,
    getAllProductsFirestore,
    setSearchedProductsArray,
  }
)(NoSideBar);
