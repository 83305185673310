import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../myOrders.css";
import "./my-request.css";
import {
  getAllBookingsOfSingleUserRedux,
  getOrderTrackingResultRedux,
  uploadRefundApplyRedux,
} from "../../../../actions";
import PaymentModal from "./paymentModal";
import LogisticsModal from "./logisticsModal";
import { HashLoader } from "react-spinners";
import Success from "./success.png";
class MyRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      pending: true,
      approved: false,
      reject: false,
      toggleModal: true,
      toggleLogisticsModal: true,
      booking: null,
      productRequestArray: [],
      submit_loader: false,
      searchOrder: "",
      filterByCategory: "",
    };
  }
  componentDidMount = () => {
    if (this.props.currentUser.id) {
      this.props.getAllBookingsOfSingleUserRedux(this.props.currentUser.id);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.currentUser.id &&
      nextProps.currentUser.id !== this.props.currentUser.id
    ) {
      this.props.getAllBookingsOfSingleUserRedux(nextProps.currentUser.id);
    }
  };

  startToggleModal = async (productRequestArray) => {
    if (productRequestArray == null) {
      this.setState({
        toggleModal: !this.state.toggleModal,
        productRequestArray: [],
      });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        productRequestArray,
      });
    }
  };
  startToggleLogisticsModal = async (booking) => {
    if (booking == null) {
      this.setState({
        toggleLogisticsModal: !this.state.toggleLogisticsModal,
        booking: null,
      });
    } else {
      this.setState({
        toggleLogisticsModal: !this.state.toggleLogisticsModal,
        booking,
      });
    }
  };

  getSingleShopTotal = (shop) => {
    const { currency } = this.props;
    let total = 0;
    if (shop.items) {
      shop.items.map((item) => {
        item.skus.map((sku) => {
          total += parseInt(
            parseFloat(sku.price) * parseInt(sku.totalQuantity)
          );
        });
      });
    }

    return total;
  };

  getTotalPaid = (booking) => {
    let totalPaid = 0;
    if (booking.payments && booking.payments.length > 0) {
      booking.payments.map((paymnet) => {
        totalPaid += parseInt(paymnet.amount);
      });
    }
    return totalPaid;
  };
  getTotalDue = (booking) => {
    let totalPaid = 0;
    let totalDue = 0;
    let totalBill = 0;
    if (booking.payments && booking.payments.length > 0) {
      booking.payments.map((paymnet) => {
        totalPaid += parseInt(paymnet.amount);
      });
    }
    if (booking.shippingChargeCustomer && booking.shippingChargeCustomer != 0) {
      totalBill = booking.orderTotal
        ? parseInt(booking.orderTotal) +
          parseInt(booking.shippingChargeCustomer) +
          parseInt(booking.localShipping)
        : this.getSingleShopTotal(booking) +
          parseInt(booking.shippingChargeCustomer) +
          parseInt(booking.localShipping);
    } else {
      totalBill = booking.orderTotal
        ? booking.orderTotal
        : this.getSingleShopTotal(booking);
    }
    totalDue = parseInt(totalBill) - parseInt(totalPaid);
    return totalDue;
  };

  applyForRefund = async () => {
    if (this.state.reason && this.state.paymentMethod && this.state.bankInfo) {
      let refundObj = {
        reason: this.state.reason,
        paymentMethod: this.state.paymentMethod,
        bankInfo: this.state.bankInfo,
        refundId: new Date().getTime(),
        order: this.state.order,
      };
      await this.props.uploadRefundApplyRedux({
        ...refundObj,
        productRequest: true,
      });
      document.getElementById("refund-info-close").click();
      document.getElementById("success-info-open").click();
    } else {
      alert("Please fill all the information");
      return;
    }
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  render() {
    const { currentUser, bookingsArray } = this.props;
    console.log(currentUser);
    const pendingBookings = bookingsArray.filter(
      (booking) => booking.orderStatus === "Pending"
    );
    const approvedBookings = bookingsArray
      .filter((booking) => booking.orderStatus !== "Pending")
      .filter((booking) => booking.orderStatus !== "Reject")
      .filter((booking) => booking.orderStatus !== "Delivered");
    const deliveredBookings = bookingsArray.filter(
      (booking) => booking.orderStatus === "Delivered"
    );

    let bookingsToShow = [];
    if (this.state.pending) {
      bookingsToShow = pendingBookings;
    } else if (this.state.approved) {
      bookingsToShow = approvedBookings;
    } else if (this.state.reject) {
      bookingsToShow = deliveredBookings;
    }

    if (this.state.searchOrder) {
      bookingsToShow = bookingsToShow.filter(
        (booking) =>
          (booking.trackingNo &&
            booking.trackingNo
              .toLowerCase()
              .includes(this.state.searchOrder.toLowerCase())) ||
          (booking.date &&
            booking.date
              .toLowerCase()
              .includes(this.state.searchOrder.toLowerCase())) ||
          (booking.productType &&
            booking.productType
              .toLowerCase()
              .includes(this.state.searchOrder.toLowerCase())) ||
          (booking.bookingId &&
            booking.bookingId
              .toLowerCase()
              .includes(this.state.searchOrder.toLowerCase()))
      );
    }

    if (this.state.filterByCategory) {
      if (
        this.state.filterByCategory == "Paid" ||
        this.state.filterByCategory == "Partially Paid"
      ) {
        bookingsToShow = bookingsToShow.filter(
          (booking) => booking.paymentStatus == this.state.filterByCategory
        );
      } else if (this.state.filterByCategory == "Not Paid") {
        bookingsToShow = bookingsToShow.filter(
          (booking) =>
            booking.paymentStatus == "Not Paid" || !booking.paymentStatus
        );
      } else {
        bookingsToShow = bookingsToShow.filter(
          (booking) => booking.orderStatus == this.state.filterByCategory
        );
      }
    }

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        <PaymentModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          pendingOrders={this.state.productRequestArray}
        />
        <LogisticsModal
          toggleLogisticsModal={this.state.toggleLogisticsModal}
          startToggleLogisticsModal={this.startToggleLogisticsModal}
          booking={this.state.booking}
        />

        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-3 left-list-column">
                <div className="left-list-container">
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Buy & Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        style={{ color: "#f54c3c" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-request`
                          );
                        }}
                      >
                        My Request Products
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-orders`
                          );
                        }}
                      >
                        My Orders
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-request`
                          );
                        }}
                      >
                        My Booking
                      </p>

                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Profile
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/information`
                          );
                        }}
                      >
                        Information
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/security`
                          );
                        }}
                      >
                        Security
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.approved ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: true,
                        pending: false,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Approved ({approvedBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.pending ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: true,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Pending ({pendingBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.reject ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: false,
                        reject: true,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Delivered ({deliveredBookings.length})
                  </div>
                </div>
                <div
                  className="row search-bar-row"
                  style={{
                    padding: "20px",
                    paddingRight: "0px",
                    justifyContent: "space-between",
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ maxWidth: "30%" }}>
                    <select
                      title="Filter by category"
                      required
                      name="filterByCategory"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.filterByCategory}
                    >
                      <option value="">Filter by category </option>

                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Partially Paid">Partially Paid</option>
                      <option value="purhcaseLater">Not Paid</option>
                      <option value="pending">Pending</option>
                      <option value="Purchased">Purchased</option>
                      <option value="Received in Warehouse">
                        Received in Warehouse (abroad)
                      </option>
                      <option value="Ready for fly">Ready for fly</option>
                      <option value="Bangladesh customs">
                        Bangladesh customs
                      </option>

                      <option value="Paicart warehouse">
                        Paicart warehouse
                      </option>
                      <option value="Delivered">Delivered</option>
                      <option value="Reject">Rejected</option>
                    </select>
                  </div>
                  <div style={{ maxWidth: "30%" }}>
                    <form className="form-inline subscribe-form">
                      <div className="form-group mx-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="searchOrder"
                          value={this.state.searchOrder}
                          onChange={this.handleChange}
                          placeholder="Search Order"
                          style={{
                            padding: 8,
                            width: 250,
                            borderColor: "gainsboro",
                            borderRadius: 5,
                            fontSize: 14,
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-solid"
                        style={{ padding: "7px 10px", marginLeft: -50 }}
                      >
                        <i
                          className="icofont-search"
                          style={{ fontWeight: "bold" }}
                        />
                      </button>
                    </form>
                  </div>
                </div>

                {bookingsToShow.length > 0 ? (
                  <>
                    <div className="row title-header">
                      <div className="col-4">Goods</div>
                      <div className="col">Unit Price (taka)</div>
                      <div className="col">Quantity</div>
                      <div className="col">Total Amount</div>
                      <div className="col">Payment Status</div>
                    </div>
                    {bookingsToShow.map((booking) => {
                      return (
                        <div>
                          <div className="row each-order-container">
                            <div className="d-flex flex-row order-header">
                              <div style={{ fontSize: 11, marginTop: 2 }}>
                                <i
                                  className="icofont-brand-natgeo"
                                  style={{ color: "black" }}
                                />
                                &nbsp;Booking Id: {booking.bookingId} &nbsp;
                                &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  marginTop: 2,
                                }}
                              >
                                booking date: {booking.date}
                              </div>

                              <div
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  marginTop: 2,
                                }}
                              >
                                <i
                                  className="icofont-brand-aliexpress"
                                  style={{ color: "darkorange", fontSize: 14 }}
                                />{" "}
                                booking status:{" "}
                                <span
                                  style={{
                                    color: "#ff8084",
                                    fontWeight: "bold",
                                    fontSize: 10,
                                  }}
                                >
                                  {booking.orderStatus}
                                </span>
                              </div>

                              <div>
                                <i
                                  className="icofont-book-mark"
                                  style={{ color: "black" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-row"
                            style={{
                              fontSize: 13,
                              color: "gray",
                              padding: 10,
                              paddingLeft: 30,
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div>
                                Tracking No:&nbsp;
                                <span style={{ color: "darkgray" }}>
                                  {booking.trackingNo
                                    ? booking.trackingNo
                                    : "not yet ordered"}
                                </span>
                              </div>
                              <div
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  background: "orange",
                                  border: "2px solid gainsboro",
                                  borderRadius: 7,
                                  padding: "0px 5px",
                                  display: "inline",
                                  fontSize: 10,
                                  marginLeft: 7,
                                  alignContent: "center",
                                  marginBottom: "2px",
                                }}
                                onClick={() => {
                                  this.startToggleModal([booking]);
                                }}
                              >
                                Pay now
                              </div>
                            </div>
                            {booking.shippingChargeCustomer &&
                            booking.shippingChargeCustomer !== 0 ? (
                              <Link
                                to={`${
                                  process.env.PUBLIC_URL
                                }/pages/dashboard/buy-and-ship-for/invoice/${
                                  booking.bookingId
                                }?productRequest=true`}
                                target="_blank"
                              >
                                <div
                                  style={{
                                    color: "white",
                                    padding: "2px 7px",
                                    border: "2px solid gainsboro",
                                    borderRadius: 7,
                                    backgroundColor: "#a3422b",
                                    cursor: "pointer",
                                    fontSize: 10,
                                    textAlign: "center",
                                    marginRight: 12,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  <i
                                    className="icofont-dollar"
                                    style={{ fontWeight: "bold" }}
                                  />{" "}
                                  invoice
                                </div>
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row products-box">
                            <div
                              className="col-8"
                              style={{ borderRight: "1px solid gainsboro" }}
                            >
                              <div
                                className="row"
                                style={{
                                  padding: 5,
                                  borderTop: "1px solid gainsboro",

                                  paddingBottom: 25,
                                }}
                              >
                                <div
                                  className="col-2"
                                  style={{ margin: "auto" }}
                                >
                                  <div
                                    style={{
                                      minHeight: 80,
                                      minWidth: 80,
                                      maxWidth: 80,
                                      maxHeight: 80,
                                      overflow: "hidden",
                                      backgroundImage: `url(${
                                        booking.imageUrl
                                      })`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      border: "1px solid gainsboro",
                                      borderRadius: 5,
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 11,
                                    }}
                                  >
                                    Store: {booking.store}
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 11,
                                    }}
                                  >
                                    Product type: {booking.productType}
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 11,
                                    }}
                                  >
                                    Shipping Rate :{" "}
                                    {booking.customerRate
                                      ? `${booking.customerRate}tk/kg`
                                      : booking.shippingRate
                                      ? `${booking.shippingRate}tk/kg`
                                      : "Not given yet"}
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 11,
                                      marginTop: 20,
                                    }}
                                  >
                                    {booking.productVariant}
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 12,
                                    }}
                                  >
                                    {booking.note ? <>{booking.note}</> : null}
                                  </div>
                                </div>
                                <div
                                  className="col"
                                  style={{ color: "rgb(98 98 98)" }}
                                >
                                  {booking.unitPrice
                                    ? `${booking.unitPrice}Tk`
                                    : "not given yet"}
                                </div>
                                <div
                                  className="col"
                                  style={{ color: "rgb(98 98 98)" }}
                                >
                                  {booking.productQuantity}
                                </div>
                              </div>

                              {booking.orderStatus !== "Reject" && (
                                <div
                                  className="row"
                                  style={{
                                    margin: 0,
                                    marginBottom: 40,
                                    marginTop: 65,
                                  }}
                                >
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 1
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-paper" />
                                    <span>Pending</span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 2
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-tick-boxed" />
                                    <span>Approved</span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 3
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-building-alt" />
                                    <span>Abroad</span>
                                    <br />
                                    <span style={{ top: "7px" }}>
                                      Warehouse
                                    </span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 4
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-airplane-alt" />
                                    <span style={{ left: "-15px" }}>
                                      Ready{" "}
                                    </span>
                                    <br />
                                    <span style={{ top: "7px", left: "-15px" }}>
                                      for fly
                                    </span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 5
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-shield-alt" />
                                    <span>Bangladesh</span>
                                    <br />
                                    <span style={{ top: "7px" }}> customs</span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 6
                                        ? "each-progress"
                                        : "each-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-castle" />
                                    <span>Paicart</span>
                                    <br />
                                    <span style={{ top: "7px" }}>
                                      {" "}
                                      warehouse
                                    </span>
                                  </div>
                                  <div
                                    className={`col ${
                                      booking.shipmentStatusScore >= 7
                                        ? "last-progress"
                                        : "last-progress-2"
                                    }`}
                                  >
                                    <i className="icofont-tick-mark" />
                                    <span>Delivered</span>
                                  </div>
                                </div>
                              )}

                              {(booking.paymentStatus == "Paid" ||
                                booking.paymentStatus == "Partially Paid") && (
                                <>
                                  {booking.refundStatus ? (
                                    <div
                                      style={{
                                        marginTop: 20,
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: "white",
                                          background:
                                            booking.refundStatus == "rejected"
                                              ? "red"
                                              : "green",
                                          border: "1px solid gainsboro",
                                          borderRadius: 7,
                                          padding: "1px 5px",
                                          display: "inline",
                                          fontSize: 10,
                                          marginTop: 5,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Refund {booking.refundStatus}!
                                      </div>
                                    </div>
                                  ) : (
                                    <div style={{ marginTop: 20 }}>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          color: "white",
                                          background: "#8d8d8d",
                                          border: "1px solid gainsboro",
                                          borderRadius: 7,
                                          padding: "1px 5px",
                                          display: "inline",
                                          fontSize: 10,
                                          marginTop: 5,
                                          fontWeight: "bold",
                                        }}
                                        data-toggle="modal"
                                        data-target="#refundModal"
                                        onClick={() => {
                                          this.setState({
                                            order: booking,
                                            reason: "",
                                            paymentMethod: "",
                                            bankInfo: "",
                                          });
                                        }}
                                      >
                                        Apply for refund
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {booking.refund && booking.refund !== 0 ? (
                                <div
                                  style={{
                                    color: "gray",
                                    fontSize: 10,
                                    marginLeft: 3,
                                  }}
                                >
                                  Refunded amount:
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 10,
                                      color: "#555",
                                    }}
                                  >
                                    {booking.refund}Tk
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="col-4"
                              style={{
                                paddingTop: 5,
                                borderTop: "1px solid gainsboro",
                                position: "relative",
                                paddingBottom: 20,
                              }}
                            >
                              <div className="row">
                                <div className="col-6">
                                  <div
                                    style={{ fontWeight: "bold", fontSize: 16 }}
                                  >
                                    {booking.actualTotal
                                      ? booking.actualTotal
                                      : this.getSingleShopTotal(booking)}
                                    Tk
                                  </div>
                                  {booking.shippingChargeCustomer &&
                                  booking.shippingChargeCustomer !== 0 ? (
                                    <>
                                      <div
                                        style={{ color: "gray", fontSize: 10 }}
                                      >
                                        Total weight:{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "#444",
                                          }}
                                        >
                                          {booking.receivedWeight} kg
                                        </span>
                                      </div>
                                      <div
                                        style={{ color: "gray", fontSize: 10 }}
                                      >
                                        Customs & Shippping rate:{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "#444",
                                          }}
                                        >
                                          {booking.customerRate} tk/kg
                                        </span>
                                      </div>
                                      <div
                                        style={{ color: "gray", fontSize: 10 }}
                                      >
                                        Total Shipping charge (CN to BD):{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "#444",
                                          }}
                                        >
                                          {booking.shippingChargeCustomer} Tk
                                        </span>
                                      </div>
                                      <div
                                        style={{ color: "gray", fontSize: 10 }}
                                      >
                                        China local courier charge:{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "#444",
                                          }}
                                        >
                                          {booking.localShipping} Tk
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16,
                                          marginTop: 20,
                                        }}
                                      >
                                        {booking.actualTotal
                                          ? parseInt(booking.actualTotal) +
                                            parseInt(
                                              booking.shippingChargeCustomer
                                            ) +
                                            parseInt(booking.localShipping)
                                          : this.getSingleShopTotal(booking) +
                                            parseInt(
                                              booking.shippingChargeCustomer
                                            ) +
                                            parseInt(booking.localShipping)}
                                        Tk
                                        <div
                                          style={{
                                            fontSize: 10,
                                            color: "gray",
                                            fontWeight: "lighter",
                                          }}
                                        >
                                          including shipping charge
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{ color: "gray", fontSize: 10 }}
                                      >
                                        excluding shipping charge
                                      </div>
                                      <div
                                        style={{
                                          color: "gray",
                                          fontSize: 10,
                                          marginTop: 25,
                                        }}
                                      >
                                        China local courier charge:{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "#444",
                                          }}
                                        >
                                          {booking.localShipping
                                            ? `${booking.localShipping}Tk`
                                            : "Not given yet"}
                                        </span>
                                        <div
                                          style={{
                                            color: "gray",
                                            fontSize: 10,
                                          }}
                                        >
                                          Customs & Shipping Rate:{" "}
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              color: "#444",
                                            }}
                                          >
                                            {booking.customerRate
                                              ? `${booking.customerRate}tk/kg`
                                              : "Not given yet"}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                      marginTop: 25,
                                    }}
                                  >
                                    Total Paid:
                                    <span
                                      style={{
                                        color: "#555",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      {this.getTotalPaid(booking)}
                                      Tk
                                    </span>
                                  </div>
                                  {booking.couponAmount &&
                                  booking.couponAmount !== 0 ? (
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                      }}
                                    >
                                      Coupon:
                                      <span
                                        style={{
                                          color: "#555",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        {booking.couponAmount}
                                        Tk
                                      </span>
                                    </div>
                                  ) : null}
                                  {booking.offer && booking.offer !== 0 ? (
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                      }}
                                    >
                                      Discount:
                                      <span
                                        style={{
                                          color: "#555",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        {Math.round(
                                          booking.actualTotalAfterCoupon *
                                            (booking.offer.split("%")[0] / 100)
                                        )}
                                        Tk
                                      </span>
                                    </div>
                                  ) : null}
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                      marginBottom: 35,
                                    }}
                                  >
                                    Total Due:
                                    <span
                                      style={{
                                        color: "#ff8084",
                                        fontWeight: "bold",
                                        fontSize: 14,
                                      }}
                                    >
                                      {" "}
                                      {this.getTotalDue(booking)}
                                      Tk
                                    </span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {booking.paymentStatus == "Paid" ||
                                  booking.paymentStatus == "Partially Paid" ||
                                  !booking.paymentStatus ? (
                                    <>
                                      <div
                                        style={{
                                          color: "white",
                                          background:
                                            booking.paymentStatus == "Paid"
                                              ? "darkgreen"
                                              : booking.paymentStatus ==
                                                "Partially Paid"
                                              ? "darkorange"
                                              : !booking.paymentStatus
                                              ? "#596fa8"
                                              : "white",
                                          border: "2px solid gainsboro",
                                          borderRadius: 7,
                                          padding: "1px 5px",
                                          display: "inline",
                                          fontSize: 11,
                                          marginTop: 5,
                                        }}
                                      >
                                        {booking.paymentStatus == "Paid"
                                          ? "Paid"
                                          : booking.paymentStatus ==
                                            "Partially Paid"
                                          ? "Partially Paid"
                                          : !booking.paymentStatus
                                          ? "Not Paid"
                                          : "Not Paid"}
                                      </div>
                                    </>
                                  ) : (
                                    <div style={{ position: "relative" }}>
                                      <div
                                        className="myDIV"
                                        style={{
                                          color: "white",
                                          background: "#da62da",
                                          border: "2px solid gainsboro",
                                          borderRadius: 7,
                                          padding: "1px 5px",
                                          display: "inline",
                                          fontSize: 11,
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {booking.paymentStatus}
                                      </div>
                                      <div
                                        className="hide"
                                        style={{
                                          position: "absolute",
                                          padding: "10px",
                                          borderRadius: 5,
                                          border: "1px solid #18768f",
                                          maxWidth: 120,
                                          minWidth: 120,
                                          zIndex: 100,
                                          background: "#da62da",
                                          fontSize: 11,
                                          color: "white",
                                          right: "0px",
                                          top: "20px",
                                        }}
                                      >
                                        {
                                          "Your payment request is pending.Please wait till your payments get approved by one of our employee."
                                        }
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  color:
                                    booking.shippingChargeCustomer &&
                                    booking.shippingChargeCustomer !== 0
                                      ? "green"
                                      : "#ff8084",
                                  fontSize: 11,
                                  position: "absolute",
                                  bottom: 0,
                                }}
                              >
                                {booking.shippingChargeCustomer &&
                                booking.shippingChargeCustomer !== 0
                                  ? "***China to Bangladesh customs & shipping charge included."
                                  : "***China to Bangladesh customs & shipping charge will be included later."}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="main-order-section">
                    <div className="col-sm-12">
                      <div>
                        <div
                          className="col-sm-12 empty-cart-cls text-center"
                          style={{ marginTop: 50 }}
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/empty-search.jpg`}
                            className="img-fluid mb-4"
                            alt=""
                          />
                          <h3 style={{ paddingBottom: 50 }}>
                            <strong>No product requests</strong>
                          </h3>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 40, textAlign: "center" }}>
                        <Link to="/request-for-product">
                          <button
                            className="btn btn-solid"
                            style={{
                              padding: 6,
                              fontSize: 10,
                              borderRadius: 5,
                            }}
                          >
                            Do a product request
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div>
              <div className="d-flex flex-row main-navigator-option">
                <div
                  className="buy-ship-for-me-mobile"
                  style={{ borderBottom: "2px solid #f54c3c" }}
                >
                  <div>Buy & Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "#f54c3c",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-request`
                        );
                      }}
                    >
                      My Request Products
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-orders`
                        );
                      }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 160 }}>
                  <div>Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-request`
                        );
                      }}
                    >
                      My Booking
                    </div>

                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 100 }}>
                  <div>Profile</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/information`
                        );
                      }}
                    >
                      Information
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/security`
                        );
                      }}
                    >
                      Security
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.approved ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: true,
                        pending: false,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Approved ({approvedBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.pending ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: true,
                        reject: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Pending ({pendingBookings.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.reject ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        approved: false,
                        pending: false,
                        reject: true,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Delivered ({deliveredBookings.length})
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    justifyContent: "space-between",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div className="col-5">
                    <select
                      title="Filter by category"
                      required
                      name="filterByCategory"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.filterByCategory}
                      style={{ fontSize: 11 }}
                    >
                      <option value="">Filter by category </option>
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Partially Paid">Partially Paid</option>
                      <option value="purhcaseLater">Not Paid</option>
                      <option value="pending">Pending</option>
                      <option value="Purchased">Purchased</option>
                      <option value="Received in Warehouse">
                        Received in Warehouse (abroad)
                      </option>
                      <option value="Ready for fly">Ready for fly</option>
                      <option value="Bangladesh customs">
                        Bangladesh customs
                      </option>

                      <option value="Paicart warehouse">
                        Paicart warehouse
                      </option>
                      <option value="Delivered">Delivered</option>
                      <option value="Reject">Rejected</option>
                    </select>
                  </div>
                  <div className="col-7">
                    <form className="form-inline subscribe-form">
                      <input
                        type="text"
                        className="form-control extra-height"
                        id="exampleFormControlInput1"
                        name="searchOrder"
                        value={this.state.searchOrder}
                        onChange={this.handleChange}
                        placeholder="Search Order"
                        style={{
                          padding: 8,
                          width: 250,
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />

                      <button
                        type="submit"
                        className="btn btn-solid"
                        style={{ position: "absolute", right: "12px" }}
                      >
                        <i
                          className="icofont-search"
                          style={{ fontWeight: "bold" }}
                        />
                      </button>
                    </form>
                  </div>
                </div>
                {bookingsToShow.length > 0 ? (
                  <>
                    {bookingsToShow.map((booking) => {
                      return (
                        <div style={{ borderRadius: 10, marginBottom: 20 }}>
                          <div className="each-order-container-1">
                            <div
                              className="order-header-1"
                              style={{ borderBottom: "1px solid gainsboro" }}
                            >
                              <div style={{ fontSize: 12 }}>
                                Booking Id: {booking.bookingId} <br />
                                <span
                                  style={{
                                    fontSize: 10,
                                    color: "gray",
                                  }}
                                >
                                  Date: {booking.date}
                                </span>
                              </div>

                              <div>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "orange",
                                    color: "white",
                                    background: "orange",
                                    border: "1px solid gainsboro",
                                    borderRadius: 7,
                                    padding: "1px 5px",
                                    display: "inline",
                                    fontSize: 11,
                                    marginTop: 5,
                                  }}
                                  onClick={() => {
                                    this.startToggleModal([booking]);
                                  }}
                                >
                                  Pay now
                                </div>
                                {booking.shippingChargeCustomer &&
                                booking.shippingChargeCustomer !== 0 ? (
                                  <Link
                                    to={`${
                                      process.env.PUBLIC_URL
                                    }/pages/dashboard/buy-and-ship-for/invoice/${
                                      booking.bookingId
                                    }?productRequest=true`}
                                    target="_blank"
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",

                                        color: "white",
                                        background: "#a3422b",
                                        border: "1px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 11,
                                        marginTop: 5,
                                        fontWeight: "bold",
                                        marginLeft: 10,
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="icofont-dollar"
                                        style={{ fontWeight: "bold" }}
                                      />{" "}
                                      invoice
                                    </div>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="products-box"
                            style={{
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                          >
                            <div>
                              <div className="row">
                                <div className="col-3">
                                  <div
                                    style={{
                                      minHeight: 60,
                                      minWidth: 60,
                                      maxHeight: 60,
                                      maxWidth: 60,
                                      overflow: "hidden",
                                      backgroundImage: `url(${
                                        booking.imageUrl
                                      })`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      border: "1px solid gainsboro",
                                      borderRadius: 5,
                                    }}
                                  />
                                </div>
                                <div
                                  className="col"
                                  style={{
                                    padding: "0px 10px",
                                    paddingLeft: 0,
                                  }}
                                >
                                  <div
                                    style={{ color: "#4a4a4a", fontSize: 8 }}
                                  >
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                      }}
                                    >
                                      Store: {booking.store}
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                      }}
                                    >
                                      Product type: {booking.productType}
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                      }}
                                    >
                                      Shipping Rate:{" "}
                                      {booking.customerRate
                                        ? `${booking.customerRate}tk/kg`
                                        : booking.shippingRate
                                        ? `${booking.shippingRate}tk/kg`
                                        : "Not given yet"}
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                        marginTop: 20,
                                      }}
                                    >
                                      {booking.productVariant}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="col-3"
                                >
                                  <div
                                    style={{
                                      color: "rgb(98 98 98)",
                                      fontSize: 11,
                                    }}
                                  >
                                    {booking.unitPrice ? (
                                      `${booking.unitPrice}Tk`
                                    ) : (
                                      <div
                                        style={{
                                          color: "gray",
                                          fontSize: 10,
                                          fontWeight: "lighter",
                                        }}
                                      >
                                        unit Price: not given yet
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 11,
                                      marginLeft: 10,
                                    }}
                                  >
                                    X{booking.productQuantity}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 5,
                                marginTop: 10,
                              }}
                            >
                              <div>
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Order status:{" "}
                                  <span
                                    style={{
                                      color: "#ff8084",
                                      fontWeight: "bold",
                                      fontSize: 10,
                                    }}
                                  >
                                    {booking.orderStatus}
                                  </span>{" "}
                                  <br />
                                </div>
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Payment Status:{" "}
                                  {booking.paymentStatus == "Paid" ||
                                  booking.paymentStatus == "Partially Paid" ||
                                  !booking.paymentStatus ? (
                                    <div
                                      style={{
                                        color: "white",
                                        background:
                                          booking.paymentStatus == "Paid"
                                            ? "darkgreen"
                                            : booking.paymentStatus ==
                                              "Partially Paid"
                                            ? "darkorange"
                                            : !booking.paymentStatus
                                            ? "#596fa8"
                                            : "white",
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 7,
                                        marginTop: 5,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {booking.paymentStatus == "Paid"
                                        ? "Paid"
                                        : booking.paymentStatus ==
                                          "Partially Paid"
                                        ? "Partially Paid"
                                        : !booking.paymentStatus
                                        ? "Not Paid"
                                        : "Not Paid"}
                                    </div>
                                  ) : (
                                    <span style={{ position: "relative" }}>
                                      <div
                                        style={{
                                          color: "white",
                                          background: "#da62da",
                                          border: "1px solid gainsboro",
                                          borderRadius: 5,
                                          padding: "1px 5px",
                                          display: "inline",
                                          fontSize: 7,
                                          marginTop: 5,
                                          fontWeight: "bold",
                                        }}
                                        className="myDIV"
                                      >
                                        {booking.paymentStatus}
                                      </div>
                                      <div
                                        className="hide"
                                        style={{
                                          position: "absolute",
                                          padding: "10px",
                                          borderRadius: 5,
                                          border: "1px solid #18768f",
                                          maxWidth: 120,
                                          minWidth: 120,
                                          zIndex: 100,
                                          background: "#da62da",
                                          fontSize: 10,
                                          color: "white",
                                          right: "0px",
                                          top: "12px",
                                        }}
                                      >
                                        {
                                          "Your payment request is pending.Please wait till your payments get approved by one of our employee."
                                        }
                                      </div>
                                    </span>
                                  )}
                                </div>
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Tracking No:{" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#444",
                                      fontSize: 10,
                                    }}
                                  >
                                    {booking.trackingNo || "Not given yet"}
                                  </span>
                                </div>
                                {booking.shippingChargeCustomer &&
                                booking.shippingChargeCustomer !== 0 ? (
                                  <>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      Total weight:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {booking.receivedWeight} kg
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      Customs & Shippping rate:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {booking.customerRate} tk/kg
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      Total Shipping charge (CN to BD):{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {booking.shippingChargeCustomer} Tk
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      China local courier charge:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {booking.localShipping} Tk
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      Product Price:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {booking.actualTotal
                                          ? booking.actualTotal
                                          : this.getSingleShopTotal(booking)}
                                        Tk
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 16,
                                        marginTop: 20,
                                      }}
                                    >
                                      {booking.actualTotal
                                        ? parseInt(booking.actualTotal) +
                                          parseInt(
                                            booking.shippingChargeCustomer
                                          ) +
                                          parseInt(booking.localShipping)
                                        : this.getSingleShopTotal(booking) +
                                          parseInt(
                                            booking.shippingChargeCustomer
                                          ) +
                                          parseInt(booking.localShipping)}
                                      Tk
                                      <div
                                        style={{
                                          fontSize: 10,
                                          color: "gray",
                                          fontWeight: "lighter",
                                          marginTop: -3,
                                        }}
                                      >
                                        including shipping charge
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      China local courier charge:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                          fontSize: 10,
                                        }}
                                      >
                                        {booking.localShipping
                                          ? `${booking.localShipping}Tk`
                                          : "Not given yet"}
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      Customs & Shippping rate:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                          fontSize: 10,
                                        }}
                                      >
                                        {booking.customerRate
                                          ? `${booking.customerRate}tk/kg`
                                          : "Not given yet"}
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 11 }}
                                    >
                                      Product Price:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {booking.actualTotal
                                          ? booking.actualTotal
                                          : this.getSingleShopTotal(booking)}
                                        Tk
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 16,
                                        marginTop: 20,
                                      }}
                                    >
                                      {booking.actualTotal
                                        ? booking.actualTotal
                                        : this.getSingleShopTotal(booking)}
                                      Tk
                                      <div
                                        style={{
                                          fontSize: 10,
                                          color: "gray",
                                          fontWeight: "lighter",
                                          marginTop: -3,
                                        }}
                                      >
                                        excluding shipping charge
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div>
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Total Paid{" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#444",
                                    }}
                                  >
                                    Tk {this.getTotalPaid(booking)}
                                  </span>
                                </div>
                                {booking.couponAmount &&
                                booking.couponAmount !== 0 ? (
                                  <div style={{ color: "gray", fontSize: 11 }}>
                                    Coupon{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#444",
                                      }}
                                    >
                                      Tk {booking.couponAmount}
                                    </span>
                                  </div>
                                ) : null}
                                {booking.offer && booking.offer !== 0 ? (
                                  <div style={{ color: "gray", fontSize: 11 }}>
                                    Discount{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#444",
                                      }}
                                    >
                                      Tk{" "}
                                      {Math.round(
                                        booking.actualTotalAfterCoupon *
                                          (booking.offer.split("%")[0] / 100)
                                      )}
                                    </span>
                                  </div>
                                ) : null}
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Total Due{" "}
                                  <span
                                    style={{
                                      color: "#ff8084",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Tk {this.getTotalDue(booking)}
                                  </span>
                                </div>
                                {(booking.paymentStatus == "Paid" ||
                                  booking.paymentStatus ==
                                    "Partially Paid") && (
                                  <>
                                    {booking.refundStatus ? (
                                      <div
                                        style={{
                                          marginTop:
                                            booking.refund &&
                                            booking.refund !== 0
                                              ? 20
                                              : 0,
                                        }}
                                      >
                                        <div
                                          style={{
                                            color: "white",
                                            background:
                                              booking.refundStatus == "rejected"
                                                ? "red"
                                                : "green",
                                            border: "1px solid gainsboro",
                                            borderRadius: 5,
                                            padding: "1px 5px",
                                            display: "inline",
                                            fontSize: 7,
                                            marginTop: 50,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Refund {booking.refundStatus}!
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          color: "white",
                                          background: "#8d8d8d",
                                          border: "1px solid gainsboro",
                                          borderRadius: 5,
                                          padding: "2px 5px",
                                          display: "inline",
                                          fontSize: 7,
                                          marginTop: 50,
                                          fontWeight: "bold",
                                        }}
                                        data-toggle="modal"
                                        data-target="#refundModal"
                                        onClick={() => {
                                          this.setState({
                                            order: booking,
                                            reason: "",
                                            paymentMethod: "",
                                            bankInfo: "",
                                          });
                                        }}
                                      >
                                        Apply for refund
                                      </div>
                                    )}
                                  </>
                                )}
                                <div>
                                  {booking.refund && booking.refund != 0 ? (
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 11,
                                        marginLeft: 3,
                                      }}
                                    >
                                      Refunded amount: <br />
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 10,
                                          color: "#555",
                                        }}
                                      >
                                        {booking.refund}Tk
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            {booking.orderStatus !== "Reject" && (
                              <div
                                className="row"
                                style={{
                                  margin: 0,
                                  marginBottom: 20,
                                  marginTop: 30,
                                }}
                              >
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 1
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-paper" />
                                  <span>Pending</span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 2
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-tick-boxed" />
                                  <span>Approved</span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 3
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-building-alt" />
                                  <span>Abroad</span>
                                  <br />
                                  <span style={{ top: "-10px" }}>
                                    Warehouse
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 4
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-airplane-alt" />
                                  <span style={{ left: "-15px" }}>Ready </span>
                                  <br />
                                  <span style={{ top: "-10px", left: "-15px" }}>
                                    fly
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 5
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-shield-alt" />
                                  <span>Bangladesh</span>
                                  <br />
                                  <span style={{ top: "-10px" }}> customs</span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 6
                                      ? "each-progress-3"
                                      : "each-progress-4"
                                  }`}
                                >
                                  <i className="icofont-castle" />
                                  <span>Paicart</span>
                                  <br />
                                  <span style={{ top: "-10px" }}>
                                    {" "}
                                    warehouse
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    booking.shipmentStatusScore >= 7
                                      ? "last-progress-3"
                                      : "last-progress-4"
                                  }`}
                                >
                                  <i className="icofont-tick-mark" />
                                  <span>Delivered</span>
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                color:
                                  booking.shippingChargeCustomer &&
                                  booking.shippingChargeCustomer !== 0
                                    ? "green"
                                    : "#ff8084",
                                fontSize: 10,
                                marginTop: 10,
                              }}
                            >
                              {booking.shippingChargeCustomer &&
                              booking.shippingChargeCustomer !== 0
                                ? "***China to Bangladesh customs & shipping charge included."
                                : "***China to Bangladesh customs & shipping charge will be included later."}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="main-order-section">
                    <div className="col-sm-12">
                      <div>
                        <div className="col-sm-12 empty-cart-cls text-center">
                          <img
                            style={{ maxWidth: "50%", marginTop: 20 }}
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/empty-search.jpg`}
                            className="img-fluid mb-4"
                            alt=""
                          />

                          <h5>No product requests</h5>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 40, textAlign: "center" }}>
                        <Link to="/request-for-product">
                          <button
                            className="btn btn-solid"
                            style={{
                              padding: 6,
                              fontSize: 8,
                              borderRadius: 5,
                            }}
                          >
                            Do a product request
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="refundModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: 1000000000 }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{ borderBottom: "1px solid black" }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Apply for refund
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="refund-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      marginBottom: 25,
                    }}
                  >
                    Booking Id:{this.state.order && this.state.order.bookingId}
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Select the reason
                    </label>
                    <select
                      title=""
                      name="reason"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.reason}
                      required
                    >
                      <option value="">Select reason for refund </option>
                      <option value="Out of stock">Out of stock</option>
                      <option value="Forbidden product">
                        Forbidden product
                      </option>
                      <option value="Fake product/price">
                        Fake product/price
                      </option>
                      <option value="I don’t want to buy">
                        I don’t want to buy
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Select method
                    </label>
                    <select
                      title=""
                      name="paymentMethod"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.paymentMethod}
                      required
                    >
                      <option value="">Select payment receive method </option>
                      <option value="Bkash">Bkash</option>
                      <option value="Bank">Bank</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Bkash Number/Bank Information
                    </label>
                    <textarea
                      name="bankInfo"
                      type="text"
                      value={this.state.bankInfo}
                      onChange={this.handleChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Bkash Number/Bank Information"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                        height: 150,
                        textAlign: "left",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.applyForRefund();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="successModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: 1000000000 }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="company-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="container" style={{ padding: 10 }}>
                    <div
                      className="row"
                      style={{ padding: 10, justifyContent: "center" }}
                    >
                      <img style={{ height: 60, width: 80 }} src={Success} />
                    </div>
                    <div
                      className="row"
                      style={{
                        padding: 10,
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      Your refund reqeust is submitted!
                    </div>
                    <div style={{ color: "gray", textAlign: "center" }}>
                      After verifying your request You will get your refund
                      amount after 7 working days.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="success-info-open"
          data-toggle="modal"
          data-target="#successModal"
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  bookingsArray: state.bookingRequests.bookingsArrayOfSingleUser,
});

export default connect(
  mapStateToProps,
  {
    getAllBookingsOfSingleUserRedux,
    getOrderTrackingResultRedux,
    uploadRefundApplyRedux,
  }
)(MyRequests);
