import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import { HashLoader } from "react-spinners";
import { getOrderTrackingResultRedux } from "../../actions";
import "./ship-for-me.css";
import "./track-order.css";
import { connect } from "react-redux";
import { getOrderOrShipmentRequest } from "../../firebase/firebase.utils";
class TrackOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      visual: "INPUT",
      trackingError: false,
      trackingNo: "",
      submit_loader: false,
    };
  }
  componentDidMount = async () => {};

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { orderTrackingResult } = this.props;
    let parcelObj = {};
    if (orderTrackingResult) {
      parcelObj = orderTrackingResult.parcelsArray[0];
    }
    return (
      <div>
        <Helmet>
          <title>Paicart | Track your order</title>
        </Helmet>
        <Breadcrumb title={"Track Order"} />
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container track-order-container">
            {this.state.visual === "INPUT" && (
              <div
                style={{
                  backgroundColor: "#11677e",
                  borderRadius: 5,
                  padding: 30,
                  paddingTop: 10,
                }}
              >
                <div className="row track-your-order-txt">Track Your Order</div>
                <form style={{ marginBottom: 20 }}>
                  <div className="form-row">
                    <div className="input-container-div">
                      <input
                        style={{
                          borderRadius: 5,
                          height: 50,
                        }}
                        type="text"
                        name="trackingNo"
                        value={this.state.trackingNo}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Enter your Tracking No/Order Id"
                        required
                      />
                    </div>
                  </div>
                  {this.state.trackingError && (
                    <div className="form-row tracking-error-div">
                      Please input Tracking No/Order Id
                    </div>
                  )}
                  <div className="form-row m-2">
                    <div
                      className="track-order-button-div"
                      onClick={async () => {
                        console.log(this.state.trackingNo);
                        if (this.state.trackingNo) {
                          this.setState({
                            submit_loader: true,
                            trackingError: false,
                          });
                          let trackingNo = await getOrderOrShipmentRequest(
                            this.state.trackingNo
                          );
                          console.log(trackingNo);
                          await this.props.getOrderTrackingResultRedux(
                            trackingNo ? trackingNo : this.state.trackingNo
                          );

                          this.setState({
                            visual: "RESULT",
                            submit_loader: false,
                          });
                        } else {
                          this.setState({
                            trackingError: true,
                          });
                        }
                      }}
                    >
                      {!this.state.submit_loader && (
                        <i
                          className="fa fa-search"
                          style={{
                            fontSize: 15,
                            textAlign: "center",
                            cursor: "pointer",
                            color: "white",
                            fontWeight: "bold",
                            paddingLeft: 5,
                          }}
                        >
                          Track Order
                        </i>
                      )}
                      <HashLoader
                        loading={this.state.submit_loader}
                        color="white"
                        size={25}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {this.state.visual === "RESULT" && (
              <div className="result-container">
                <div
                  className="d-flex flex-row cross-icon"
                  style={{
                    justifyContent: "flex-end",
                    padding: 10,
                    marginTop: 10,
                  }}
                >
                  <i
                    onClick={() => {
                      this.setState({
                        visual: "INPUT",
                        trackingNo: "",
                      });
                    }}
                    className="fa fa-times"
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#555",
                      fontWeight: "bold",
                      paddingLeft: 5,
                    }}
                  />
                </div>
                {orderTrackingResult ? (
                  <>
                    <div
                      className="row tracking-no-header"
                      style={{
                        color: "#555",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      Tracking No : {parcelObj.trackingNo}
                    </div>
                    <div className="section-containing-container">
                      <div className="flex-options">
                        <div style={{ backgroundColor: "white", padding: 3 }}>
                          <div
                            style={{
                              backgroundColor: "#d6ebf7",
                              padding: 20,
                              border: "2px dashed #9edcff",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                padding: 10,
                                paddingBottom: 20,
                              }}
                            >
                              আপনার অনুসন্ধান এর ফলাফল:{" "}
                            </div>
                            <table className="table table-bordered">
                              <tbody>
                                {/* <tr>
                                  <td
                                    colSpan="2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Order Id
                                  </td>
                                  <td style={{ fontWeight: "bold" }}>Gbb359</td>
                                </tr> */}
                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Lot No
                                  </td>
                                  <td>
                                    {orderTrackingResult.lotArray.length == 1
                                      ? orderTrackingResult.lotObj.lotNo
                                      : orderTrackingResult.lotArray.map(
                                          (lot) => `${lot},`
                                        )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Country</td>
                                  <td>
                                    {" "}
                                    {orderTrackingResult.lotObj.selectCountry}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Shipment Method</td>
                                  <td>
                                    {" "}
                                    {orderTrackingResult.lotObj.shipmentMethod}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Shipping Line</td>
                                  <td>
                                    {orderTrackingResult.lotObj.shippingLine}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Carton No</td>
                                  <td>
                                    {orderTrackingResult.parcelsArray.map(
                                      (parcel) => `${parcel.parcelId},`
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Delivery Date (approx)</td>
                                  <td>
                                    {orderTrackingResult.lotObj.arrivalDate}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              style={{
                                fontSize: 13,
                                marginBottom: 20,
                                marginTop: 10,
                              }}
                            >
                              * উপরোল্লেখিত ডেলিভারি তারিখ একটি সম্ভাব্য তারিখ।
                              এয়ারপোর্ট, কাস্টমজনিত সুবিধা অসুবিধার কারণে পণ্য
                              ডেলিভারি পেতে কিছুসময় দেরী অথবা দ্রুত পাওয়া যেতে
                              পারে। অতিরিক্ত দেরী হলে আমাদের কাস্টমার সার্ভিসতে
                              যোগাযোগ করুন ।
                            </div>
                            <div style={{ fontSize: 13 }}>
                              *আপনার প্রেরিত পণ্যের প্রতিটা বক্সের উপরে আপনার
                              শিপিং মার্ক/নাম, পণ্যের বিবরণ (পণ্যের নাম, কত পিস)
                              অবশ্যই অবশ্যই লেখা থাকতে হবে।
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex-options"
                        // style={{
                        //   backgroundColor: "gray",
                        //   padding: 5,
                        // }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            border: "2px dashed gray",
                          }}
                        >
                          <div>
                            <div className="px-3 m-4">
                              <div className="row mt-3" />
                              <div className="row mt-4">
                                <div className="col order-tracking-bar">
                                  {orderTrackingResult.lotObj
                                    .shipmentStatusScore >= 1 ||
                                  (parcelObj && parcelObj.parcelStatus) ? (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="icofont-industries"
                                          style={{
                                            backgroundColor: "#18768f",
                                            color: "white",
                                            border: "2px soild gainsboro",
                                          }}
                                        />
                                        <div
                                          className="progress-bar-line"
                                          style={{
                                            backgroundColor: "#18768f",
                                          }}
                                        />
                                      </div>
                                      &nbsp;
                                      <p
                                        style={{
                                          color: "#18768f",
                                        }}
                                      >
                                        Abroad Warehouse <br />
                                        {
                                          orderTrackingResult.lotObj
                                            .abroadWarehouseDate
                                        }
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i className="icofont-industries" />
                                        <div className="progress-bar-line" />
                                      </div>
                                      &nbsp; <p>Abroad Warehouse</p>
                                    </span>
                                  )}
                                  {orderTrackingResult.lotObj
                                    .shipmentStatusScore >= 2 ||
                                  (parcelObj && parcelObj.parcelStatus) ? (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="icofont-police"
                                          style={{
                                            backgroundColor: "#18768f",
                                            color: "white",
                                            border: "2px soild gainsboro",
                                          }}
                                        />
                                        <div
                                          className="progress-bar-line"
                                          style={{
                                            backgroundColor: "#18768f",
                                          }}
                                        />
                                      </div>
                                      &nbsp;
                                      <p
                                        style={{
                                          color: "#18768f",
                                        }}
                                      >
                                        Abroad Customs
                                        <br />
                                        {
                                          orderTrackingResult.lotObj
                                            .abroadCustomsDate
                                        }
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i className="icofont-police" />
                                        <div className="progress-bar-line" />
                                      </div>
                                      &nbsp; <p>Abroad Customs</p>
                                    </span>
                                  )}
                                  {orderTrackingResult.lotObj
                                    .shipmentStatusScore >= 3 ||
                                  (parcelObj && parcelObj.parcelStatus) ? (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="icofont-airplane-alt"
                                          style={{
                                            backgroundColor: "#18768f",
                                            color: "white",
                                            border: "2px soild gainsboro",
                                          }}
                                        />
                                        <div
                                          className="progress-bar-line"
                                          style={{
                                            backgroundColor: "#18768f",
                                          }}
                                        />
                                      </div>
                                      &nbsp;
                                      <p
                                        style={{
                                          color: "#18768f",
                                        }}
                                      >
                                        Ready to Fly
                                        <br />
                                        {
                                          orderTrackingResult.lotObj
                                            .readyToFlyDate
                                        }
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i className="icofont-airplane-alt" />
                                        <div className="progress-bar-line" />
                                      </div>
                                      &nbsp; <p>Ready to Fly</p>
                                    </span>
                                  )}
                                  {orderTrackingResult.lotObj
                                    .shipmentStatusScore >= 4 ||
                                  (parcelObj && parcelObj.parcelStatus) ? (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="icofont-police"
                                          style={{
                                            backgroundColor: "#18768f",
                                            color: "white",
                                            border: "2px soild gainsboro",
                                          }}
                                        />
                                        <div
                                          className="progress-bar-line"
                                          style={{
                                            backgroundColor: "#18768f",
                                          }}
                                        />
                                      </div>
                                      &nbsp;
                                      <p
                                        style={{
                                          color: "#18768f",
                                        }}
                                      >
                                        Bangladesh Customs
                                        <br />
                                        {
                                          orderTrackingResult.lotObj
                                            .bangladeshCustomsDate
                                        }
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i className="icofont-police" />
                                        <div className="progress-bar-line" />
                                      </div>
                                      &nbsp; <p>Bangladesh Customs</p>
                                    </span>
                                  )}
                                  {orderTrackingResult.lotObj
                                    .shipmentStatusScore >= 5 ||
                                  (parcelObj && parcelObj.parcelStatus) ? (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="icofont-home"
                                          style={{
                                            backgroundColor: "#18768f",
                                            color: "white",
                                            border: "2px soild gainsboro",
                                          }}
                                        />
                                        <div
                                          className="progress-bar-line"
                                          style={{
                                            backgroundColor: "#18768f",
                                          }}
                                        />
                                      </div>
                                      &nbsp;
                                      <p
                                        style={{
                                          color: "#18768f",
                                        }}
                                      >
                                        Custom Released
                                        <br />
                                        {
                                          orderTrackingResult.lotObj
                                            .localWarehouseDate
                                        }
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i className="icofont-home" />
                                        <div className="progress-bar-line" />
                                      </div>
                                      &nbsp; <p>Custom Released</p>
                                    </span>
                                  )}
                                  {parcelObj && parcelObj.parcelStatus ? (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          className="icofont-tick-mark"
                                          style={{
                                            backgroundColor: "#18768f",
                                            color: "white",
                                            border: "2px soild gainsboro",
                                          }}
                                        />
                                      </div>
                                      &nbsp;
                                      <p
                                        style={{
                                          color: "#18768f",
                                        }}
                                      >
                                        Paicart warehouse
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="d-flex flex-row">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i className="icofont-tick-mark" />
                                      </div>
                                      &nbsp; <p>Paicart warehouse</p>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {false ? (
                              <>
                                <div className="row mt-3">
                                  <div className="col">
                                    <div
                                      className="center-head"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      <span
                                        className="bg-light-gray"
                                        style={{
                                          textTransform: "none",
                                          color: "purple",
                                        }}
                                      >
                                        শিপমেন্ট সংক্রান্ত নোটিশ
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "gray",
                                    fontSize: "80%",
                                    padding: "10px",
                                    paddingTop: "5px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  Notice
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="row"
                      style={{
                        color: "#18768f",
                        fontWeight: "bold",
                        justifyContent: "center",
                        padding: 10,
                        fontSize: 30,
                      }}
                    >
                      Order not Shipped yet!
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderTrackingResult: state.orders.orderTrackingResult,
  };
};
export default connect(
  mapStateToProps,
  { getOrderTrackingResultRedux }
)(TrackOrder);
