import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { getSingleShipForMeRedux } from "../../../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./trackingDetails.css";
import { HashLoader } from "react-spinners";
class TrackingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      loader: false,
      days: -1,
      minutes: 0,
      seconds: 0,
      hours: 0,
    };
  }
  componentDidMount = () => {
    const bookingId = this.props.match.params.orderId;
    this.setState({ loader: true });
    this.props.getSingleShipForMeRedux(bookingId);
    this.setState({ loader: false });
    this.timerID = setInterval(() => this.tick(), 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timerID);
  };

  tick = () => {
    const { orderTrackingResult, shipForMeObj } = this.props;
    let productObj = shipForMeObj;

    if (productObj && productObj.validTo) {
      const [day, month, year] = productObj.validTo.split("-");
      let dateFuture = new Date(
        parseInt(year),
        parseInt(month - 1),
        parseInt(day)
      );
      let dateNow = new Date();
      var seconds = Math.floor((dateFuture - dateNow) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      this.setState({
        days: days + 1,
        hours,
        minutes,
        seconds,
      });
    }
  };

  render() {
    console.log(this.props.match);
    const { orderTrackingResult, shipForMeObj } = this.props;
    let booking = shipForMeObj;

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />
        {booking && (
          <section
            className="section-b-space mobile-view"
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <div className="container" style={{ padding: 10 }}>
              <div className="row" style={{ padding: 10 }}>
                <>
                  <div style={{ borderRadius: 10, marginBottom: 20 }}>
                    <div
                      className="each-order-container-1"
                      style={{
                        borderBottom: "1px solid gainsboro",
                        position: "relative",
                      }}
                    >
                      <div className="order-header-1">
                        {booking && booking.status == "Rates Provided" && (
                          <>
                            {booking &&
                            booking.validTo &&
                            this.state.days > -1 ? (
                              <div
                                style={{
                                  color: "black",
                                  position: "absolute",
                                  right: 0,
                                  top: "-13px",
                                }}
                              >
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "black",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  {this.state.days}days
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "black",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  {this.state.hours}hrs
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "black",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  {this.state.minutes}mins
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "black",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  {this.state.seconds}secs
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: "black",
                                  position: "absolute",
                                  right: 0,
                                  top: "-13px",
                                }}
                              >
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "red",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  00days
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "red",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  00hrs
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "red",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  00mins
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    background: "white",
                                    padding: 3,
                                    borderRadius: 7,
                                    color: "red",
                                    border: "1px solid #ec345b",
                                    fontSize: 8,
                                  }}
                                >
                                  00secs
                                </span>
                              </div>
                            )}
                          </>
                        )}
                        <div style={{ fontSize: 10, marginLeft: -8 }}>
                          booking number: {booking.bookingId}
                          <span
                            style={{
                              fontSize: 8,
                              color: "darkgray",
                              marginLeft: 10,
                            }}
                          >
                            {booking.date}
                          </span>
                        </div>

                        <div
                          style={{
                            color: "darkorange",
                            fontSize: 9,
                            fontWeight: "bold",
                          }}
                        >
                          {booking.paymentStatus ? (
                            <div
                              style={{
                                color:
                                  booking.paymentStatus == "Paid"
                                    ? "green"
                                    : booking.paymentStatus == "Partially Paid"
                                    ? "darkorange"
                                    : "red",
                              }}
                            >
                              {booking.paymentStatus}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    >
                      <div>
                        <div className="row">
                          <div
                            className="col"
                            style={{
                              fontSize: 9,
                              fontWeight: "bold",
                              marginBottom: 7,
                            }}
                          >
                            {booking.othersProductName}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <div
                              style={{
                                height: 60,
                                width: 60,
                                overflow: "hidden",
                                backgroundImage: `url(${booking.imageUrl})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div
                            style={{
                              padding: "0px 10px",
                              paddingLeft: 0,
                            }}
                            className="col"
                          >
                            <div style={{ color: "#4a4a4a", fontSize: 8 }}>
                              {booking.productType} <br />
                              {booking.productQuantity}pc
                              <br />
                              {booking.productVariant}
                              <br />
                              {booking.trackingNo && (
                                <div
                                  style={{
                                    fontSize: 8,
                                    color: "gray",
                                    marginTop: 5,
                                  }}
                                >
                                  Tracking no:{" "}
                                  <span style={{ color: "black" }}>
                                    {booking.trackingNo}
                                  </span>
                                </div>
                              )}
                              <div
                                style={{
                                  fontSize: 8,
                                  color: "gray",
                                }}
                              >
                                booking status:{" "}
                                <span
                                  style={{
                                    color: booking
                                      ? booking.status == "Pending"
                                        ? "gainsboro"
                                        : booking.status == "Rates Provided"
                                        ? "gray"
                                        : booking.status ==
                                          "Received in Warehouse"
                                        ? "orange"
                                        : booking.status == "Ready for fly"
                                        ? "darkorange"
                                        : booking.status == "Bangladesh customs"
                                        ? "chocolate"
                                        : booking.status == "Paicart warehouse"
                                        ? "green"
                                        : booking.status ==
                                          "Bangladesh warehouse"
                                        ? "#00a3a3"
                                        : booking.status == "Delivered"
                                        ? "darkgreen"
                                        : "red"
                                      : "white",
                                  }}
                                >
                                  {booking.status}
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: 8,
                                  color: "gray",
                                }}
                              >
                                Payment status:{" "}
                                <span
                                  style={{
                                    backgroundColor: booking
                                      ? booking.paymentStatus ==
                                        "Partially Paid"
                                        ? "darkorange"
                                        : booking.paymentStatus == "Not Paid"
                                        ? "#596fa8"
                                        : booking.paymentStatus == "Paid"
                                        ? "green"
                                        : "#596fa8"
                                      : "#596fa8",
                                    color: "white",

                                    padding: "0px 4px",
                                    borderRadius: 5,
                                    border: "2px solid gainsboro",
                                  }}
                                >
                                  {booking.paymentStatus || "Not Paid"}
                                </span>
                              </div>
                              {booking.warehouse && (
                                <div
                                  style={{
                                    fontSize: 8,
                                    color: "gray",
                                    marginTop: 5,
                                  }}
                                >
                                  Warehouse address:{" "}
                                  <span style={{ color: "black" }}>
                                    {booking.warehouse}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="col-3"
                          >
                            <div
                              style={{
                                color: "rgb(98 98 98)",
                                fontSize: 11,
                              }}
                            >
                              {booking.weight}kg
                            </div>
                            <div
                              style={{
                                color: "gray",
                                fontSize: 11,
                                marginLeft: 10,
                              }}
                            >
                              X{booking.result.perKg}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: 5,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 7,
                            color: "gray",
                            maxWidth: 50,
                            paddingLeft: 10,
                          }}
                        />
                        <div style={{ fontSize: 7, color: "gray" }}>
                          {booking.localShipping
                            ? `Including china courier cost ${
                                booking.localShipping
                              }Tk`
                            : null}
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          borderTop: "1px solid gainsboro",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <div className="col">
                          <div
                            style={{
                              color: "#18768f",
                              fontSize: 9,
                              fontWeight: "bold",
                              marginTop: 10,
                              marginBottom: 7,
                            }}
                          >
                            Product Information
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                            }}
                          >
                            Origin country:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.shipFrom}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Ship By:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.method}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Ship To:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.shipTo}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Valid To:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.validTo}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Brand/Non-brand:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.productBrand}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Product contains:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.productContains}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Carton quantity:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.ctnQuantity}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Total CBM:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.totalCbm}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Received weight:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.receivedWeight}kg
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Rate/kg:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.customerRate}Tk/kg
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Packaging & other cost:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.otherCost || 0}Tk
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Discount:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.discount || 0}Tk
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Refund amount:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.refund || 0}Tk
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Total Shipping charge:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.shippingChargeCustomer}Tk
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Total Paid:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.totalPaid || 0}Tk
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Refund note:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.refundNote}
                            </span>
                          </div>
                          {booking.waybillImage && (
                            <div
                              style={{
                                fontSize: 8,
                                color: "#555",
                                marginTop: 5,
                              }}
                            >
                              Waybill Image: <br />
                              <span
                                style={{
                                  color: "black",
                                  textTransform: "capitalize",
                                }}
                              >
                                <img
                                  src={booking.waybillImage}
                                  style={{
                                    height: 50,
                                    width: 50,
                                    border: "1px solid gainsboro",
                                    borderRadius: 5,
                                  }}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <div
                            style={{
                              color: "#18768f",
                              fontSize: 9,
                              fontWeight: "bold",
                              marginTop: 10,
                              marginBottom: 7,
                            }}
                          >
                            Shipping Status
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                            }}
                          >
                            Booking Date:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.date}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Rates Provided:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["Rates ProvidedDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Abroad Warehouse:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["Received in WarehouseDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Ready for fly:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["Ready for flyDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Bangladesh customs:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["Bangladesh customsDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Custom Released:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["Bangladesh warehouseDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Paicart Warehouse:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["Paicart WarehouseDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Delivered:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking["DeliveredDate"]}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Delivered by:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.deliveredBy}
                            </span>
                          </div>
                          <div
                            style={{
                              color: "#18768f",
                              fontSize: 9,
                              fontWeight: "bold",
                              marginTop: 20,
                              marginBottom: 7,
                            }}
                          >
                            Shipping Information
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                            }}
                          >
                            Customer Name:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.displayName}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Shipping mark:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.shippingMark}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Tracking No:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.trackingNo}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Cargo Company :{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              AR shipper
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Lot No:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.lotNo &&
                                booking.lotNo.toString().replace("CB", "AR")}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 8,
                              color: "#555",
                              marginTop: 5,
                            }}
                          >
                            Carton number:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {booking.ctnNo &&
                                booking.ctnNo.toString().replace("CB", "AR")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.user.currentUser,
    shipForMeObj: state.bookingRequests.shipForMeObj,
  };
};

export default connect(
  mapStateToProps,
  { getSingleShipForMeRedux }
)(TrackingDetails);
