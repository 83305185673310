import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { uploadShipmentPaymentRequestRedux } from "../../../../actions/index";
import { uploadImageRechargeRequest } from "../../../../firebase/firebase.utils";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

import sslCommerze from "./assets/ssl commerze.png";
import "./makePaymentModal.css";
import { CircleLoader } from "react-spinners";
import man from "./assets/plus image.jpeg";
import brac from "../../../../assets/images/portfolio/payment-logo/brack.png";
import dbbl from "../../../../assets/images/portfolio/payment-logo/dbbl.png";
import city from "../../../../assets/images/portfolio/payment-logo/city.png";
import asia from "../../../../assets/images/portfolio/payment-logo/asia.png";
import bkash from "../../../../assets/images/portfolio/payment-logo/bkash.png";
import rocket from "../../../../assets/images/portfolio/payment-logo/rocket.png";
import nagad from "../../../../assets/images/portfolio/payment-logo/nagad.png";
import axios from "axios";
import "./paymentModal.css";
import { Alert } from "react-bootstrap";
class PaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      method: "",
      submit_loader: false,
      file: "",
      loading: false,
      imageUrl: man,
      mobileBanking: "bkash",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.productRequestArray &&
      nextProps.productRequestArray.length > 0
    ) {
      const invoiceArray = nextProps.productRequestArray;
      this.setState({
        invoiceArray,
      });
    }
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { currentUser, productRequestArray } = this.props;
    const { email, password, invoiceArray } = this.state;
    const { checkedInvoices } = this.props;
    const invoicesToPay = invoiceArray;
    let total = 0;
    console.log(invoicesToPay);
    if (invoicesToPay.length > 0) {
      invoicesToPay.forEach(
        (invoice) => (total += parseInt(invoice.shippingChargeCustomer))
      );
    }
    console.log(total);

    if (this.state.loading) {
      alert("please wait for the image to be uploded.");
      return;
    }
    if (this.state.imageUrl === man) {
      alert("Please upload your transactions copy.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }
    const paymentId = Math.floor(Math.random() * Date.now());

    this.setState({ submit_loader: true });

    let date = new Date();
    await this.props.uploadShipmentPaymentRequestRedux({
      status: "pending",
      paymentId,
      date: date.toLocaleDateString("en-GB"),
      amount: total,
      imageUrl: this.state.imageUrl,
      method: this.state.method,
      productRequestArray,
      userId: currentUser.id,
      displayName: currentUser.displayName,
      time: date.getTime(),
    });

    toast.success(
      "Your payment Request is submitted! Our team member will confirm it soon"
    );
    this.setState({
      amount: "",
      imageUrl: man,
      method: "",
      file: "",
      loading: false,
      submit_loader: false,
    });
    document.getElementById("modal-close-icon-payment-modal-2").click();
  };

  readyForSslCheckout = async (total) => {
    const { currentUser } = this.props;
    this.setState({
      submit_loader: true,
    });
    const data = {
      name: `${currentUser.userId}-${currentUser.displayName}`,
      amount: Math.round(total),
      from:
        window.location.pathname == "/user-my-invoice-express"
          ? "express"
          : "d2d",
    };
    const response = await axios.post(
      "http://localhost:5000/init-sslCommerz",
      data
    );
    if (response.data && response.data.length > 30) {
      window.location.replace(response.data);
    } else {
      alert("checkout failed. Try again later.");
    }

    this.setState({ submit_loader: false });
  };

  render() {
    const { email, password, invoiceArray } = this.state;
    const { checkedInvoices } = this.props;
    const invoicesToPay = invoiceArray;
    let total = 0;
    if (invoicesToPay.length > 0) {
      invoicesToPay.forEach(
        (invoice) => (total += parseInt(invoice.shippingChargeCustomer))
      );
    }
    console.log(total);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div
              className="modal-content final-payment-modal"
              style={{
                backgroundColor: "white",
                overflowY: "scroll",
                maxHeight: 600,
              }}
            >
              <div className="modal-body p-0">
                <section
                  className="pos-rel bg-light-gray"
                  style={{ paddingTop: 0 }}
                >
                  <div className="container-fluid make-payment-container">
                    <a
                      id="modal-close-icon-payment-modal-2"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.props.startToggleModal(null);
                      }}
                    >
                      <i
                        className="icofont-close-line"
                        style={{
                          color: "black",
                          fontWeight: "bolder",
                          paddingRight: 5,
                        }}
                      />
                    </a>
                    <div className="d-lg-flex justify-content-center no-gutters mb-spacer-md">
                      <div className="col">
                        <div
                          className="px-3 padding-right-disable"
                          style={{ paddingRight: 0 }}
                        >
                          <h2 className="mb-1 fw-6 make-payment-header">
                            Make Payment
                          </h2>

                          <div
                            style={{
                              color: "black",

                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              marginBottom: "25px",
                            }}
                          >
                            Total Amount: {total}Tk
                          </div>
                          <Tabs style={{ marginBottom: "40px" }}>
                            <TabList
                              className="nav nav-tabs tab-coupon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "15px",
                              }}
                            >
                              <Tab className="nav-link each-option">
                                Direct Deposite
                              </Tab>
                              <Tab
                                disabled={true}
                                className="nav-link each-option"
                                onClick={() => {
                                  alert(
                                    "Sorry. We are currently working on it. Please do Direct deposite now"
                                  );
                                }}
                              >
                                Mobile Banking
                              </Tab>
                              <Tab
                                disabled={true}
                                className="nav-link each-option"
                                onClick={() => {
                                  alert(
                                    "Sorry. We are currently working on it. Please do Direct deposite now"
                                  );
                                }}
                              >
                                Credit/Debit Card
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <div>
                                <div
                                  style={{
                                    color: "white",
                                    padding: "10px",
                                    backgroundColor: "rgb(1, 70, 109)",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  নিন্মোক্ত একাউন্টতে জমা/ট্রান্সফার করুন
                                </div>
                                <div className="table-responsive-md">
                                  <table className="table">
                                    <thead>
                                      <tr className="table-light">
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          Bank/Mobile Banking
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          AC Name
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          AC No.
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          {this.state.method == "City"
                                            ? "Branch"
                                            : "AC type"}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        className="table-light"
                                        key="City Bank"
                                        style={{
                                          borderBottom: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">
                                          <img
                                            src={city}
                                            style={{
                                              height: 40,
                                              width: 40,
                                              maxHeight: 40,
                                              maxWidth: 40,
                                              border: "2px solid gainsboro",
                                              borderRadius: 5,
                                            }}
                                          />{" "}
                                          City Bank
                                        </th>
                                        <td>MD MOSTAFA SHEIKH</td>
                                        <td>
                                          2303116295001 <br />
                                          <span style={{ fontSize: 11 }}>
                                            Routing:225670228{" "}
                                          </span>
                                        </td>
                                        <td>Bhulta, Narayanganj</td>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="Bkash"
                                        style={{
                                          borderBottom: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">
                                          <img
                                            src={bkash}
                                            style={{
                                              height: 40,
                                              width: 40,
                                              maxHeight: 40,
                                              maxWidth: 40,
                                              border: "2px solid gainsboro",
                                              borderRadius: 5,
                                            }}
                                          />{" "}
                                          Bkash
                                        </th>
                                        <td>MD MOSTAFA SHEIKH </td>
                                        <td>01303‑427088</td>
                                        <td>Personal</td>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="Nagad"
                                        style={{
                                          borderBottom: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">
                                          <img
                                            src={rocket}
                                            style={{
                                              height: 40,
                                              width: 40,
                                              maxHeight: 40,
                                              maxWidth: 40,
                                              border: "2px solid gainsboro",
                                              borderRadius: 5,
                                            }}
                                          />{" "}
                                          Rocket
                                        </th>
                                        <td>MD MOSTAFA SHEIKH </td>
                                        <td>01303-4270888</td>
                                        <td>Personal</td>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="Nagad"
                                        style={{
                                          borderBottom: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">
                                          <img
                                            src={nagad}
                                            style={{
                                              height: 40,
                                              width: 40,
                                              maxHeight: 40,
                                              maxWidth: 40,
                                              border: "2px solid gainsboro",
                                              borderRadius: 5,
                                            }}
                                          />{" "}
                                          Nagad
                                        </th>
                                        <td>MD MOSTAFA SHEIKH </td>
                                        <td>01303‑427088</td>
                                        <td>Personal</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div
                                style={{
                                  maxWidth: "500px",
                                  borderRadius: "10px",
                                  background: "rgb(1, 70, 109)",
                                  padding: "40px",
                                  paddingBottom: "0px",
                                  margin: "auto",
                                }}
                              >
                                <form onSubmit={this.handleSubmit}>
                                  <div
                                    className="form-row mb-4"
                                    style={{
                                      fontSize: "140%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    জমার ছবি/স্ক্রিনশট আপলোড করুন
                                  </div>
                                  <div
                                    className="form-row"
                                    style={{
                                      fontSize: "120%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    Method
                                  </div>
                                  <div className="form-row mb-2">
                                    <select
                                      className="custom-select"
                                      name="method"
                                      value={this.state.method}
                                      onChange={this.handleChange}
                                    >
                                      <option value="">Choose Method</option>
                                      <option value="City">City Bank</option>
                                      <option value="Bkash">Bkash</option>
                                      <option value="Rocket">Rocket</option>
                                      <option value="Nagad">Nagad</option>
                                    </select>
                                  </div>
                                  <div
                                    className="form-row mb-1"
                                    style={{
                                      fontSize: "130%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    Upload Image
                                  </div>
                                  <div
                                    className="form-row mb-2"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div
                                      className="box-input-file"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      {this.state.loading ? (
                                        <div
                                          className="spinner-border"
                                          role="status"
                                          style={{
                                            color: "purple",
                                            paddingTop: "25px",
                                            backgroundColor: "white",
                                            width: 150,
                                            height: 150,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            border: "1px solid gainsboro",
                                            borderRadius: 5,
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginTop: 30,
                                              padding: 0,
                                              marginRight: 20,
                                            }}
                                          >
                                            <CircleLoader
                                              loading={this.state.loading}
                                              color="blue"
                                              size={35}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            className="img-100 lazyloaded blur-up"
                                            src={this.state.imageUrl}
                                            alt="#"
                                            style={{
                                              zIndex: 6,
                                              cursor: "pointer",
                                              maxWidth: 150,
                                              minWidth: 150,
                                              minHeight: 150,
                                              maxHeight: 150,
                                              border: "1px solid gainsboro",
                                              borderRadius: 5,
                                            }}
                                            onClick={() => {
                                              document
                                                .getElementById(
                                                  "upload-image-input"
                                                )
                                                .click();
                                            }}
                                          />

                                          <input
                                            id="upload-image-input"
                                            className="upload"
                                            type="file"
                                            style={{
                                              position: "absolute",
                                              zIndex: 5,
                                              maxWidth: "50px",
                                              marginTop: "10px",
                                            }}
                                            onChange={(e) =>
                                              this._handleImgChange(e, 0)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    className="form-row"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div
                                      className="col pt-2"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        style={{
                                          minWidth: "100px",
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          minHeight: "30px",
                                          backgroundColor: "#f54c3c",
                                          border: "1px solid #f54c3c",
                                          borderRadius: 5,
                                          padding: 10,
                                        }}
                                      >
                                        {!this.state.submit_loader && (
                                          <>Upload</>
                                        )}

                                        <CircleLoader
                                          loading={this.state.submit_loader}
                                          color="white"
                                          size={15}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </form>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    fontSize: "14px",
                                    color: "white",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  আপনার ব্যংক জমা স্লিপ অথবা ট্রান্সফার এর ছবিটি
                                  উপরে আপলোড করে পেমেন্ট সম্পন্ন করুন।
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div style={{ marginLeft: 10 }}>
                                <div
                                  style={{ fontWeight: "bold", color: "#666" }}
                                >
                                  Choose payment method:
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginTop: 6,
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.setState({
                                        mobileBanking: "bkash",
                                      });
                                    }}
                                  >
                                    <img
                                      src={bkash}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.mobileBanking == "bkash"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.mobileBanking == "bkash"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Bkash
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 12,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        mobileBanking: "rocket",
                                      });
                                    }}
                                  >
                                    <img
                                      src={rocket}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.mobileBanking == "rocket"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.mobileBanking == "rocket"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Rocket
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 12,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        mobileBanking: "nagad",
                                      });
                                    }}
                                  >
                                    <img
                                      src={nagad}
                                      style={{
                                        height: 50,
                                        maxHeight: 50,
                                        widhth: 50,
                                        maxWidth: 50,
                                        border:
                                          this.state.mobileBanking == "nagad"
                                            ? "2px solid #18768f"
                                            : "2px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />{" "}
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        textAlign: "center",
                                        color:
                                          this.state.mobileBanking == "nagad"
                                            ? "#18768f"
                                            : "#555",
                                      }}
                                    >
                                      Nagad
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="table-responsive-md"
                                  style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <table className="table">
                                    <thead>
                                      <tr
                                        className="table-light"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="col">Total</th>
                                        <th scope="col">{total}Tk</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        className="table-light"
                                        key="City Bank"
                                        style={{
                                          borderLeft: "2px solid #ebeef4",
                                          borderRight: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">Fee(1.5%)</th>
                                        <td>
                                          {parseInt(total) * (1.5 / 100)}
                                          Tk
                                        </td>
                                      </tr>
                                      <tr
                                        className="table-light"
                                        key="City Bank-1"
                                      >
                                        <th
                                          scope="row"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Amount to Pay
                                        </th>
                                        <td style={{ fontWeight: "bold" }}>
                                          {parseInt(total) * (1.5 / 100) +
                                            total}
                                          Tk
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <button
                                className="btn"
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#f54c3c",
                                  color: "white",
                                  padding: 10,
                                  borderRadius: 5,
                                }}
                                onClick={async () => {
                                  this.readyForSslCheckout(
                                    parseInt(total) * (1.5 / 100) + total
                                  );
                                }}
                              >
                                {!this.state.submit_loader && (
                                  <>Proceed to Pay</>
                                )}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </TabPanel>
                            <TabPanel>
                              <div
                                className="table-responsive-md"
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <table className="table">
                                  <thead>
                                    <tr
                                      className="table-light"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="col">Total</th>
                                      <th scope="col">{total}Tk</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className="table-light"
                                      key="City Bank-2"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="row">Fee(2.5%)</th>
                                      <td>
                                        {parseInt(total) * (2.5 / 100)}
                                        Tk
                                      </td>
                                    </tr>
                                    <tr
                                      className="table-light"
                                      key="City Bank-3"
                                    >
                                      <th
                                        scope="row"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Amount to Pay
                                      </th>
                                      <td style={{ fontWeight: "bold" }}>
                                        {parseInt(total) * (2.5 / 100) + total}
                                        Tk
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <button
                                className="btn"
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#f54c3c",
                                  color: "white",
                                  padding: 10,
                                  borderRadius: 5,
                                }}
                                onClick={async () => {
                                  this.readyForSslCheckout(
                                    parseInt(total) * (2.5 / 100)
                                  );
                                }}
                              >
                                {!this.state.submit_loader && (
                                  <>Proceed to Pay</>
                                )}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </TabPanel>
                          </Tabs>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={sslCommerze}
                              alt=""
                              style={{
                                maxWidth: "70%",
                              }}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.user.currentUser,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      uploadShipmentPaymentRequestRedux,
    }
  )(PaymentModal)
);
