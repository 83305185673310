import React, { Component } from "react";
import {
  auth,
  firestore,
  signInWithGoogle,
  singInWithFacebook,
  createUserProfileDocument,
} from "../../firebase/firebase.utils";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./login.css";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import GoogleLogo from "./login-google-logo.png";
import Axios from "axios";
import { sendOtpRedux } from "../../actions/index";
import OtpModal from "./OtpModal";
class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobileNo: "",
      currentUser: null,
      submit_loader: false,
      loader: false,
      loginBoard: false,
      login: true,
      phoneNumber: "",
    };
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loginBoard: true,
      });
    }, 50);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, mobileNo } = this.state;

    if (this.state.loader) {
      return;
    }
    try {
      this.setState({
        loader: true,
      });
      await auth.signInWithEmailAndPassword(email, password);

      auth.onAuthStateChanged(async (userAuth) => {
        if (userAuth) {
          setTimeout(() => {
            this.props.history.push("/");
            this.setState({ email: "", password: "", loader: false });
          }, 1000);
        }
      });
    } catch (error) {
      this.setState({ loader: false });
      alert(error);
    }
  };

  handleSignupSubmit = async (event) => {
    event.preventDefault();

    const {
      displayName,
      email,
      password,
      confirmPassword,
      mobileNo,
    } = this.state;

    if (password !== confirmPassword) {
      alert("passwords don't match");
      return;
    }
    if (this.state.loader) {
      return;
    }
    try {
      this.setState({
        loader: true,
      });
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      const userRef = await createUserProfileDocument(user, {
        displayName,
        mobileNo,
      });
      const snapShot = await userRef.get();
      if (snapShot.data().displayName !== "") {
        toast.success("Successfully created your account");
        this.setState({
          displayName: "",
          email: "",
          password: "",
          confirmPassword: "",
          mobileNo: "",
          loader: false,
        });
        this.props.history.push("/");
      }
    } catch (error) {
      alert(error);
      toast.warn(
        "there was an erro occurred creating your account. try again later"
      );
    }
  };

  componentWillUnmount = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSignInWithFacebook = async () => {
    try {
      await singInWithFacebook();

      if (this.props.history.location.state) {
        this.props.history.push(this.props.history.location.state.from);
      } else {
        this.props.history.push("/");
      }
    } catch (error) {
      alert(error);
    }
  };

  handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      console.log(this.props.history.location);
      if (this.props.history.location.state) {
        this.props.history.push(this.props.history.location.state.from);
      } else {
        this.props.history.push("/");
      }
    } catch (error) {
      alert(error);
    }
  };

  onSignInSubmit = async (event) => {
    event.preventDefault();
    // first send an otp
    if (
      this.state.phoneNumber.length !== 11 &&
      !this.state.phoneNumber.startsWith("0")
    ) {
      alert("Enter valid mobile number without country code.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }

    this.setState({
      submit_loader: true,
    });
    let url = `https://paicart.com/api/v1/otp-sms-send/${
      this.state.phoneNumber
    }`;
    let resultObj;
    await Axios.get(url)
      .then((response) => {
        resultObj = response;
        if (resultObj && resultObj.data && resultObj.data.error == 0) {
          toast.success("An OTP was sent to you successfully!");
          this.props.sendOtpRedux(this.state.phoneNumber);
          this.setState({ phoneNumber: "", submit_loader: false });
          window.document.getElementById("request_otp_popup_modal").click();
        } else if (resultObj && resultObj.data && resultObj.data.error == 400) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(
            "The request was rejected, due to a missing or invalid parameter."
          );
        } else if (resultObj && resultObj.data && resultObj.data.error == 403) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`You don't have permissions to perform the request.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 404) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`The requested resource not found.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 405) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Authorization required.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 409) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Unknown error occurred on Server end.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 410) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Account expired`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 411) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Reseller Account expired or suspended`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 412) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Invalid Schedule`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 413) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Invalid Sender ID`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 414) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Message is empty`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 415) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Message is too long`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 416) {
          this.setState({ submit_loader: false });
          toast.error(`No valid number found`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 417) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Insufficient balance`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 420) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Content Blocked`);
        } else {
          toast.error("There was an error.Please try again later.");
          this.setState({
            submit_loader: false,
            phoneNumber: "",
          });
        }
      })
      .catch((error) => {
        toast.error("Too many request. Please try again after some times.");
        this.setState({
          submit_loader: false,
        });
      });
  };

  render() {
    const {
      displayName,
      email,
      password,
      mobileNo,
      confirmPassword,
    } = this.state;
    return (
      <>
        <div
          className="modal fade show login-bg-image"
          id="request_login_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{
            display: "block",
            position: "relative",
            paddingBottom: 120,
            paddingTop: 40,
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal"
              style={{
                border: "1px solid gainsboro",
                boxShadow: "rgb(56 56 56) 5px 5px 8px",
              }}
            >
              <div className="modal-body p-0">
                <section
                  className="pos-rel bg-light-gray"
                  style={{ paddingTop: 0 }}
                >
                  <div className="container-fluid p-0">
                    <div
                      className="d-lg-flex justify-content-end no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div
                        className="col bg-fixed login-img-bg request_pag_img"
                        style={{ minHeight: "200px" }}
                      >
                        &nbsp;
                      </div>

                      <div className="col-md-5 col-12">
                        {this.state.login ? (
                          <div
                            className={`login-board ${
                              this.state.loginBoard ? "login-board-final" : ""
                            } `}
                          >
                            <div
                              className="p-3"
                              style={{
                                border: "1px solid gainsboro",
                                borderRadius: 10,
                                boxShadow: "0px 5px 5px 2px gray",
                              }}
                            >
                              <h2
                                className="h2-xl fw-6 sign-in-header-title"
                                style={{
                                  color: "#18768f",
                                  fontSize: "140%",
                                  fontWeight: "bolder",
                                  textAlign: "center",
                                }}
                              >
                                Sign in
                              </h2>
                              <form
                                onSubmit={this.handleSubmit}
                                className="rounded-field"
                              >
                                <div className="form-row mb-4">
                                  <div
                                    className="col"
                                    style={{ textAlign: "center" }}
                                  >
                                    Not registered?
                                    <span
                                      style={{
                                        color: "purple",
                                        marginLeft: "2px",
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          login: false,
                                          email: "",
                                          password: "",
                                        });
                                      }}
                                    >
                                      &nbsp;create an account
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row mb-2">
                                  <div className="col">
                                    <input
                                      type="text"
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      name="email"
                                      value={email}
                                      className="form-control input-field-100"
                                      placeholder="Enter your Email"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="col">
                                    <input
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      type="password"
                                      name="password"
                                      value={password}
                                      className="form-control input-field-100"
                                      placeholder="Enter your Password"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                                <Link to="/pages/forget-password">
                                  <div
                                    className="form-row mt-1 pl-2"
                                    style={{
                                      color: "purple",
                                      marginLeft: "2px",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                    }}
                                  >
                                    forgot password
                                  </div>
                                </Link>
                                <div className="form-row mt-1">
                                  <div
                                    className="col pt-2"
                                    style={{
                                      display: "flex",
                                      flex: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      type="submit"
                                      className="form-btn btn-theme bg-orange align-items-center"
                                      style={{
                                        minWidth: "130px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        background: "#18768f",
                                        padding: 10,
                                        borderRadius: 10,
                                        border: "none",
                                        minHeight: 40,
                                      }}
                                    >
                                      {!this.state.loader && (
                                        <>
                                          Sign in
                                          <i
                                            className="icofont-rounded-right"
                                            style={{ marginTop: 3 }}
                                          />
                                        </>
                                      )}

                                      <CircleLoader
                                        loading={this.state.loader}
                                        color="white"
                                        size={15}
                                      />
                                    </button>
                                  </div>
                                </div>

                                <div className="form-row mt-3">
                                  <div className="col">
                                    <div className="center-head">
                                      <span className="bg-light-gray txt-orange">
                                        Or
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-row mb-4">
                                  <div className="col">
                                    <div
                                      className="facebook-sign-in"
                                      data-dismiss="modal"
                                      data-toggle="modal"
                                      data-target="#request_phone_popup"
                                    >
                                      <span>
                                        <i className="icofont-ui-touch-phone" />
                                      </span>
                                      Sign in with Phone
                                    </div>
                                    <div
                                      className="google-sign-in"
                                      data-dismiss="modal"
                                      onClick={() =>
                                        this.handleSignInWithGoogle()
                                      }
                                    >
                                      <span>
                                        <img
                                          src={GoogleLogo}
                                          style={{
                                            height: 30,
                                            width: 30,
                                            marginTop: "-3px",
                                          }}
                                        />
                                      </span>
                                      Sign in with Google
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`login-board-signup ${
                              this.state.loginBoard ? "signup-board-final" : ""
                            } `}
                          >
                            <div
                              className="p-3"
                              style={{
                                border: "1px solid gainsboro",
                                borderRadius: 10,
                                boxShadow: "0px 5px 5px 2px gray",
                              }}
                            >
                              <h2
                                className="h2-xl fw-6 sign-in-header-title"
                                style={{
                                  color: "#18768f",
                                  fontSize: "140%",
                                  fontWeight: "bolder",
                                  textAlign: "center",
                                }}
                              >
                                Sign up
                              </h2>
                              <form
                                onSubmit={this.handleSignupSubmit}
                                className="rounded-field"
                              >
                                <div className="form-row mb-4">
                                  <div
                                    className="col"
                                    style={{ textAlign: "center" }}
                                  >
                                    Already have an account?
                                    <span
                                      style={{
                                        color: "purple",
                                        marginLeft: "2px",
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          login: true,
                                          email: "",
                                          password: "",
                                        });
                                      }}
                                    >
                                      &nbsp;Log in
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row mb-2">
                                  <div className="col">
                                    <input
                                      type="text"
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      name="displayName"
                                      value={displayName}
                                      className="form-control input-field-100"
                                      placeholder="Display Name"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row mb-2">
                                  <div className="col">
                                    <input
                                      type="text"
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      name="email"
                                      value={email}
                                      className="form-control input-field-100"
                                      placeholder="Enter your Email"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row mb-2">
                                  <div className="col">
                                    <input
                                      type="text"
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      name="mobileNo"
                                      value={mobileNo}
                                      className="form-control input-field-100"
                                      placeholder="Enter your Mobile No"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row mb-2">
                                  <div className="col">
                                    <input
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      type="password"
                                      name="password"
                                      value={password}
                                      className="form-control input-field-100"
                                      placeholder="Enter your Password"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="col">
                                    <input
                                      style={{
                                        padding: "15px",
                                        borderRadius: 10,
                                        fontSize: ".9rem",
                                        outline: "none",
                                      }}
                                      type="password"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      className="form-control input-field-100"
                                      placeholder="Confirm Password"
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="form-row mt-1">
                                  <div
                                    className="col pt-2"
                                    style={{
                                      display: "flex",
                                      flex: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      type="submit"
                                      className="form-btn btn-theme bg-orange align-items-center"
                                      style={{
                                        minWidth: "130px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        background: "#18768f",
                                        padding: 10,
                                        borderRadius: 10,
                                        border: "none",
                                        minHeight: 40,
                                      }}
                                    >
                                      {!this.state.loader && (
                                        <>
                                          Create Account
                                          <i
                                            className="icofont-rounded-right"
                                            style={{ marginTop: 3 }}
                                          />
                                        </>
                                      )}

                                      <CircleLoader
                                        loading={this.state.loader}
                                        color="white"
                                        size={15}
                                      />
                                    </button>
                                  </div>
                                </div>

                                <div className="form-row mt-3">
                                  <div className="col">
                                    <div className="center-head">
                                      <span className="bg-light-gray txt-orange">
                                        Or
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-row mb-4">
                                  <div className="col">
                                    <div
                                      className="facebook-sign-in"
                                      data-dismiss="modal"
                                      data-toggle="modal"
                                      data-target="#request_phone_popup"
                                    >
                                      <span>
                                        <i className="icofont-ui-touch-phone" />
                                      </span>
                                      Sign up with Phone
                                    </div>
                                    <div
                                      className="google-sign-in"
                                      data-dismiss="modal"
                                      onClick={() =>
                                        this.handleSignInWithGoogle()
                                      }
                                    >
                                      <span>
                                        <img
                                          src={GoogleLogo}
                                          style={{
                                            height: 30,
                                            width: 30,
                                            marginTop: "-3px",
                                          }}
                                        />
                                      </span>
                                      Sign up with Google
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="request_phone_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content phone-modal"
              style={{ background: "black" }}
            >
              <div className="modal-body p-0">
                <section
                  className="pos-rel bg-light-gray"
                  style={{ paddingTop: 50 }}
                >
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="icofont-close-line" />
                    </a>
                    <div
                      className="d-lg-flex justify-content-center"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div
                        className="col"
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingBottom: 25,
                        }}
                      >
                        <div>
                          <h2
                            className="h2-xl fw-6"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                            }}
                          >
                            Enter your Mobile no
                          </h2>
                          <form
                            noValidate="novalidate"
                            className="rounded-field"
                          >
                            <div className="form-row mb-1">
                              <div className="col">
                                <input
                                  onChange={this.handleChange}
                                  style={{ borderRadius: 5 }}
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder="Your Mobile no"
                                  value={this.state.phoneNumber}
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="col pt-2">
                                <button
                                  id="number-submit"
                                  type="submit"
                                  className="form-btn btn-theme bg-orange"
                                  onClick={this.onSignInSubmit}
                                  style={{
                                    minWidth: "130px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    border: "none",
                                    background: "#18768f",
                                    padding: "7px",
                                    borderRadius: "7px",
                                    float: "right",
                                  }}
                                >
                                  {!this.state.submit_loader && (
                                    <>
                                      Get Otp
                                      <i
                                        className="icofont-rounded-right"
                                        style={{ marginTop: 5 }}
                                      />
                                    </>
                                  )}

                                  <CircleLoader
                                    loading={this.state.submit_loader}
                                    color="white"
                                    size={15}
                                  />
                                </button>
                                <div
                                  id="request_otp_popup_modal"
                                  style={{ display: "none" }}
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_otp_popup"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <OtpModal props={this.props} />
      </>
    );
  }
}

export default withRouter(
  connect(
    null,
    { sendOtpRedux }
  )(LoginModal)
);
