import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./cart1688.css";
import { Overlay } from "react-bootstrap";
import {
  addToPendingOrdersRedux,
  removeItemFromCartRedux,
} from "../../actions";
import shop from "../../api/shop";

class Cart1688 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      checked: false,
      skus: [],
      selectAll: false,
    };
  }

  componentDidMount = () => {
    const { cart } = this.props;
    if (cart.length > 0) {
      let skus = [];
      cart.map((shop) => {
        shop.items.map((item) => {
          item.skus.map((sku) => {
            skus.push({
              ...sku,
              sku_id: `${item.id}-${sku.sku_id}`,
              shopId: shop.shopId,
              itemId: item.id,
            });
          });
        });
      });
      this.setState(
        {
          skus,
        },
        () => {
          console.log(this.state.skus);
        }
      );
    }
  };
  componentWillReceiveProps = (nextProps) => {
    const { cart } = nextProps;
    if (cart.length > 0) {
      let skus = [];
      cart.map((shop) => {
        shop.items.map((item) => {
          item.skus.map((sku) => {
            skus.push({
              ...sku,
              sku_id: `${item.id}-${sku.sku_id}`,
              shopId: shop.shopId,
              itemId: item.id,
            });
          });
        });
      });
      this.setState(
        {
          skus,
        },
        () => {
          console.log(this.state.skus);
        }
      );
    }
  };

  decrementQuantity = (sku, item) => {
    const { currency } = this.props;
    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }
    let price2 = sku.price;
    if (item.price_range) {
      let price_range = JSON.parse(item.price_range);
      if (price_range && price_range.length > 0) {
        let totalQuantity = this.getItemTotalQuantity(
          item,
          sku,
          this.state.skus,
          this.state.skus.find(
            (sku2) => sku2.sku_id == `${item.id}-${sku.sku_id}`
          ).totalQuantity > 1
            ? parseInt(
                this.state.skus.find(
                  (sku2) => sku2.sku_id == `${item.id}-${sku.sku_id}`
                ).totalQuantity
              ) - 1
            : this.state.skus.find(
                (sku2) => sku2.sku_id == `${item.id}-${sku.sku_id}`
              ).totalQuantity
        );
        console.log(item);

        console.log(totalQuantity);
        console.log(price_range);
        price_range.map((price, index2, arr) => {
          const stateQ = parseInt(totalQuantity);
          const dataQs = arr.map((p, i) => (i === 0 ? 0 : parseInt(p[0])));
          const selectedIndex = dataQs.findIndex((q, i, arr) => {
            return stateQ >= q && stateQ < (arr[i + 1] || stateQ + 1);
          });
          console.log(selectedIndex);
          const selected = selectedIndex === index2;
          console.log(selected);
          if (selected) {
            price2 = Math.round(parseFloat(price[1]) * parseFloat(taka));
          }
        });
      }
    }
    let newSkus = this.state.skus.map((sku1) => {
      if (sku1.sku_id == `${item.id}-${sku.sku_id}`) {
        if (sku1.totalQuantity > 1) {
          return {
            ...sku1,
            totalQuantity: parseInt(sku1.totalQuantity) - 1,
            price: price2,
          };
        } else {
          return sku1;
        }
      } else {
        return {
          ...sku1,
          price:
            item.price_range &&
            item.skus.find((sku) => `${item.id}-${sku.sku_id}` == sku1.sku_id)
              ? price2
              : sku1.price,
        };
      }
    });
    this.setState({
      skus: newSkus,
    });
  };
  incrementQuantity = (sku, item) => {
    const { currency } = this.props;
    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }
    let price2 = sku.price;
    if (item.price_range) {
      let price_range = JSON.parse(item.price_range);
      if (price_range && price_range.length > 0) {
        let totalQuantity = this.getItemTotalQuantity(
          item,
          sku,
          this.state.skus,
          parseInt(
            this.state.skus.find(
              (sku2) => sku2.sku_id == `${item.id}-${sku.sku_id}`
            ).totalQuantity
          ) + 1
        );
        console.log(item);
        console.log(totalQuantity);
        console.log(price_range);
        price_range.map((price, index2, arr) => {
          const stateQ = parseInt(totalQuantity);
          const dataQs = arr.map((p, i) => (i === 0 ? 0 : parseInt(p[0])));
          const selectedIndex = dataQs.findIndex((q, i, arr) => {
            return stateQ >= q && stateQ < (arr[i + 1] || stateQ + 1);
          });
          console.log(selectedIndex);
          const selected = selectedIndex === index2;
          console.log(selected);
          if (selected) {
            price2 = Math.round(parseFloat(price[1]) * parseFloat(taka));
          }
        });
      }
    }
    let newSkus = this.state.skus.map((sku1) => {
      if (sku1.sku_id == `${item.id}-${sku.sku_id}`) {
        return {
          ...sku1,
          totalQuantity: parseInt(sku1.totalQuantity) + 1,
          price: price2,
        };
      } else {
        return {
          ...sku1,
          price:
            item.price_range &&
            item.skus.find((sku) => `${item.id}-${sku.sku_id}` == sku1.sku_id)
              ? price2
              : sku1.price,
        };
      }
    });
    this.setState({
      skus: newSkus,
    });
  };

  onSelectAllChange = (e) => {
    const { cart } = this.props;
    if (e.target.checked) {
      cart.map((shop) => {
        this.setState({
          [shop.shopId]: true,
        });
        shop.items.map((item) => {
          this.setState({
            [item.id]: true,
          });
          item.skus.map((sku) => {
            let itemSku = `${item.id}-${sku.sku_id}`;
            this.setState({
              [itemSku]: true,
            });
          });
        });
      });
      this.setState({
        selectAll: true,
      });
    } else {
      cart.map((shop) => {
        this.setState({
          [shop.shopId]: false,
        });
        shop.items.map((item) => {
          this.setState({
            [item.id]: false,
          });
          item.skus.map((sku) => {
            let itemSku = `${item.id}-${sku.sku_id}`;
            this.setState({
              [itemSku]: false,
            });
          });
        });
      });
      this.setState({
        selectAll: false,
      });
    }
  };
  onShopChange = (e, shop) => {
    const { cart } = this.props;
    if (e.target.checked) {
      cart.map((shop1) => {
        if (shop1.shopId == shop.shopId) {
          shop1.items.map((item) => {
            this.setState({
              [item.id]: true,
            });
            item.skus.map((sku) => {
              let itemSku = `${item.id}-${sku.sku_id}`;
              this.setState({
                [itemSku]: true,
              });
            });
          });
        }
      });
      this.setState({
        [shop.shopId]: true,
      });
    } else {
      cart.map((shop1) => {
        if (shop1.shopId == shop.shopId) {
          shop1.items.map((item) => {
            this.setState({
              [item.id]: false,
            });
            item.skus.map((sku) => {
              let itemSku = `${item.id}-${sku.sku_id}`;
              this.setState({
                [itemSku]: false,
              });
            });
          });
        }
      });
      this.setState({
        [shop.shopId]: false,
      });
    }
    this.setState(
      {
        [shop.shopId]: !this.state[`${shop.shopId}`],
      },
      () => {
        console.log(this.state);
      }
    );
  };

  onItemChange = (e, item) => {
    const { cart } = this.props;
    if (e.target.checked) {
      cart.map((shop) => {
        shop.items.map((item1) => {
          if (item1.id == item.id) {
            item1.skus.map((sku) => {
              let itemSku = `${item1.id}-${sku.sku_id}`;
              this.setState({
                [itemSku]: true,
              });
            });
          }
        });
      });
      this.setState({
        [item.id]: true,
      });
    } else {
      cart.map((shop) => {
        shop.items.map((item1) => {
          if (item1.id == item.id) {
            item1.skus.map((sku) => {
              let itemSku = `${item1.id}-${sku.sku_id}`;
              this.setState({
                [itemSku]: false,
              });
            });
          }
        });
      });
      this.setState({
        [item.id]: false,
      });
    }
  };

  onSkuChange = (e, item, sku) => {
    const { cart } = this.props;
    if (e.target.checked) {
      let itemSku = `${item.id}-${sku.sku_id}`;
      this.setState({
        [itemSku]: true,
      });
    } else {
      let itemSku = `${item.id}-${sku.sku_id}`;
      this.setState({
        [itemSku]: false,
      });
    }
  };

  clickOnSettlement = (skus, totalAmount) => {
    const { cart, addToPendingOrdersRedux, currentUser } = this.props;
    if (totalAmount < 1000) {
      alert("Minimum order amount 1000Tk");
      return;
    }
    console.log(skus);
    let pendingOrders = [];

    cart.map((shop) => {
      let skusArray = [];
      skus.map((sku) => {
        let item = shop.items[0];
        console.log(item);
        if (sku.itemId == item.id) {
          if (pendingOrders.find((order) => order.items[0].id == sku.itemId)) {
            pendingOrders = pendingOrders.map((order) => {
              if (order.items[0].id == sku.itemId) {
                skusArray.push(sku);
                let skusWithDuplicate = [...order.items[0].skus, ...skusArray];
                const unique = [
                  ...new Set(skusWithDuplicate.map((sku) => sku.sku_id)),
                ];
                let skusWithoutDuplicate = unique.map((skuId) => {
                  return skusWithDuplicate.find((sku) => sku.sku_id == skuId);
                });
                console.log(skusWithoutDuplicate);
                return {
                  ...order,
                  items: [
                    {
                      ...order.items[0],
                      skus: skusWithoutDuplicate,
                    },
                  ],
                };
              } else {
                return order;
              }
            });
          } else {
            pendingOrders.push({
              shopId: shop.shopId,
              shopName: shop.shopName,
              items: [{ ...item, skus: [sku] }],
            });
          }
        }
      });
    });
    console.log(cart);
    console.log(pendingOrders);

    addToPendingOrdersRedux(pendingOrders);

    this.props.history.push("/dashboard/pages/place-order");
  };

  removeItemFromShop = async (item) => {
    // remove the item from firestore cart->shop and update redux state
    const { removeItemFromCartRedux, currentUser } = this.props;
    await removeItemFromCartRedux(currentUser, item);
  };

  getShopTotalQuantity = (onlySkus, shop) => {
    let total = 0;
    shop.items.map((item) => {
      onlySkus.map((sku) => {
        if (
          item.skus.find((sku1) => `${item.id}-${sku1.sku_id}` == sku.sku_id)
        ) {
          total += parseInt(sku.totalQuantity);
        }
      });
    });
    return total;
  };
  getItemTotalQuantity = (item, sku1, changedSkusArray, newQuantity) => {
    let total = 0;
    item.skus.map((sku) => {
      if (sku.sku_id == sku1.sku_id) {
        total += parseInt(newQuantity);
      } else {
        if (
          changedSkusArray.find(
            (sku2) => sku2.sku_id == `${item.id}-${sku.sku_id}`
          )
        ) {
          let changedSku = changedSkusArray.find(
            (sku2) => sku2.sku_id == `${item.id}-${sku.sku_id}`
          );
          total += parseInt(parseInt(changedSku.totalQuantity));
        } else {
          total += parseInt(parseInt(sku.totalQuantity));
        }
      }
    });
    return total;
  };

  getShopTotalTaka = (onlySkus, shop) => {
    const { currency } = this.props;
    let total = 0;
    shop.items.map((item) => {
      onlySkus.map((sku) => {
        if (
          item.skus.find((sku1) => `${item.id}-${sku1.sku_id}` == sku.sku_id)
        ) {
          total += parseInt(
            parseFloat(sku.price) * parseInt(sku.totalQuantity)
          );
        }
      });
    });
    return total;
  };

  render() {
    const { currentUser, cart, currency } = this.props;
    const { skus } = this.state;
    console.log(currentUser);
    console.log(skus);

    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }

    let allKeysTrueFalse = Object.keys(this.state);
    console.log(allKeysTrueFalse);
    let allKeysTrue = [];
    allKeysTrueFalse.map((key) => {
      if (this.state[key] == true) {
        allKeysTrue.push(key);
      }
    });
    console.log(allKeysTrue);
    let skuKeys = allKeysTrue;
    console.log(skuKeys);
    let totalQuantity = 0;
    let totalAmount = 0;
    let onlySkus = [];
    skus.map((sku) => {
      if (skuKeys.includes(sku.sku_id)) {
        onlySkus.push(sku);
        totalQuantity += parseInt(sku.totalQuantity);
        totalAmount += parseInt(
          parseFloat(sku.price) * parseFloat(sku.totalQuantity)
        );
      }
    });

    let goodCategory = 0;
    cart.map((shop) => {
      goodCategory += shop.items.length;
    });

    return (
      <div className="parent-main-container-cart">
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container">
            <div
              className="d-flex flex-row"
              style={{ marginLeft: -15, marginRight: -15 }}
            >
              <div className="col right-detail-column">
                <div className="d-flex flex-row search-bar-row">
                  <form className="form-inline subscribe-form">
                    <div className="form-group mx-sm-3">
                      <div className="purchase-title">My purchase order</div>
                    </div>
                  </form>
                </div>

                <div className="d-flex flex-row title-header-2">
                  <div className="col payment-info-header">
                    Please keep that in mind that total payable of this order is{" "}
                    <span style={{ color: "darkorange" }}>
                      not including the shipping charge (CN to BD)
                    </span>{" "}
                    and will be included later after the products are received
                    in our local warehouse.Thank you.
                  </div>
                </div>
                <div
                  className="d-flex flex-row title-header"
                  style={{ marginBottom: "-12px" }}
                >
                  <div className="col-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        checked={this.state.selectAll}
                        onChange={this.onSelectAllChange}
                      />
                      <label
                        className="form-check-label"
                        style={{ marginTop: 3 }}
                      >
                        select all
                      </label>
                    </div>
                  </div>
                  <div className="col-5">Goods</div>
                  <div className="col">
                    <div
                      className="d-flex flex-row"
                      style={{ justifyContent: "space-around" }}
                    >
                      <div>Quantity</div>
                      <div>Unit Price (taka)</div>
                      <div>Total Amount</div>
                    </div>
                  </div>
                </div>
                {cart.map((shop, index) => (
                  <div style={{ marginTop: 20 }} key={index}>
                    <div className="d-flex flex-row each-order-container">
                      <div className="d-flex flex-row order-header">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={this.state[shop.shopId]}
                              onChange={(e) => this.onShopChange(e, shop)}
                              style={{ height: 15, width: 15 }}
                            />
                          </div>
                          <div className="shop-title">
                            <i
                              className="icofont-brand-alipay"
                              style={{ color: "darkred" }}
                            />
                            &nbsp;{shop.shopName}
                            &nbsp;
                          </div>
                        </div>

                        <div
                          className="more-offers"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            target="_blank"
                            href={`/collection/${shop.shopId}?shop_name=${
                              shop.shopName
                            }`}
                            style={{ color: "black" }}
                          >
                            View store
                            <i
                              className="icofont-long-arrow-right"
                              style={{ marginTop: 2 }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {shop.items.map((item, index) => (
                      <div key={index}>
                        <div
                          className="d-flex flex-row products-box"
                          style={{
                            padding: 10,
                            paddingTop: 15,
                            borderBottom: "0px",
                          }}
                        >
                          <div className="col-2">
                            <div className="d-flex flex-row product-image-title-row">
                              <div
                                className="form-check proudct-checkbox"
                                style={{ marginTop: 2, paddingRight: 10 }}
                              >
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  value=""
                                  checked={this.state[item.id]}
                                  onChange={(e) => this.onItemChange(e, item)}
                                  style={{
                                    fontSize: 17,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                              </div>
                              <div
                                className="product-image-box"
                                onClick={() => {
                                  this.props.history.push(
                                    `/product/${item.id}`
                                  );
                                }}
                                style={{
                                  backgroundImage: `url(${item.picture})`,
                                  cursor: "pointer",
                                  border: "1px solid gainsboro",
                                  borderRadius: 5,
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className="d-flex flex-row product-title-text"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: 15,
                                paddingBottom: 5,
                              }}
                            >
                              {item.name}
                            </div>
                            <span style={{ color: "#333" }}>
                              Product Type:{item.productType}
                            </span>
                            <div style={{ marginBottom: 15 }} />
                            {item.skus.map((sku, index) => (
                              <div
                                className="d-flex flex-row size-color-variant-row"
                                style={{
                                  justifyContent: "space-between",
                                  padding: "10px",
                                }}
                                key={index}
                              >
                                {!sku.size && (
                                  <div
                                    className="form-check"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <input
                                      className="form-check-input size-input-checkbox"
                                      type="checkbox"
                                      value=""
                                      checked={this.state[sku.sku_id]}
                                      style={{
                                        fontSize: 17,
                                        height: 17,
                                        width: 17,
                                      }}
                                      onChange={(e) =>
                                        this.onSkuChange(e, item, sku)
                                      }
                                    />
                                  </div>
                                )}
                                <div
                                  className="product-image-box2"
                                  style={{
                                    backgroundImage: `url(${
                                      sku.image ? sku.image : item.picture
                                    })`,
                                    border: "1px solid gainsboro",
                                    borderRadius: 5,
                                  }}
                                />

                                <div
                                  style={{
                                    color: "gray",
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    alignSelf: "center",
                                  }}
                                  className="col-2"
                                >
                                  {sku.color && (
                                    <>
                                      <span style={{ color: "#222" }}>
                                        {item.propertyNames[0]}:
                                      </span>{" "}
                                      {sku.color}
                                    </>
                                  )}
                                </div>

                                <div
                                  className="col-4"
                                  style={{ alignSelf: "center" }}
                                >
                                  <div className="d-flex flex-row">
                                    {sku.size && (
                                      <>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input size-input-checkbox"
                                            type="checkbox"
                                            checked={
                                              this.state[
                                                `${item.id}-${sku.sku_id}`
                                              ]
                                            }
                                            value=""
                                            style={{
                                              fontSize: 17,
                                              height: 17,
                                              width: 17,
                                            }}
                                            onChange={(e) =>
                                              this.onSkuChange(e, item, sku)
                                            }
                                          />
                                        </div>{" "}
                                        <div
                                          style={{
                                            color: "gray",
                                            fontWeight: "bold",
                                            fontSize: 12,
                                          }}
                                        >
                                          &nbsp;
                                          <span style={{ color: "#222" }}>
                                            {item.propertyNames[1]}:
                                          </span>{" "}
                                          {sku.size}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="col-3"
                                  style={{ alignSelf: "center" }}
                                >
                                  <div className="d-flex flex-row">
                                    <div
                                      style={{
                                        padding: 4,
                                        paddingRight: 6,
                                        paddingLeft: 6,
                                        backgroundColor: "#18768f",
                                        border: "1px solid #18768f",
                                        borderTopLeftRadius: 3,
                                        borderBottomLeftRadius: 3,
                                        fontSize: 10,
                                      }}
                                    >
                                      <i
                                        className="icofont-minus"
                                        style={{ color: "white" }}
                                        onClick={() =>
                                          this.decrementQuantity(sku, item)
                                        }
                                      />
                                    </div>
                                    <input
                                      type="number"
                                      value={
                                        this.state.skus.find(
                                          (sku1) =>
                                            sku1.sku_id ==
                                            `${item.id}-${sku.sku_id}`
                                        ) &&
                                        this.state.skus.find(
                                          (sku1) =>
                                            sku1.sku_id ==
                                            `${item.id}-${sku.sku_id}`
                                        ).totalQuantity
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value > 0 &&
                                          e.target.value < 100001
                                        ) {
                                          let price2 = sku.price;
                                          if (item.price_range) {
                                            let price_range = JSON.parse(
                                              item.price_range
                                            );
                                            if (
                                              price_range &&
                                              price_range.length > 0
                                            ) {
                                              let totalQuantity = this.getItemTotalQuantity(
                                                item,
                                                sku,
                                                this.state.skus,
                                                e.target.value
                                              );
                                              console.log(item);

                                              console.log(totalQuantity);
                                              console.log(price_range);
                                              price_range.map(
                                                (price, index2, arr) => {
                                                  const stateQ = parseInt(
                                                    totalQuantity
                                                  );
                                                  const dataQs = arr.map(
                                                    (p, i) =>
                                                      i === 0
                                                        ? 0
                                                        : parseInt(p[0])
                                                  );
                                                  const selectedIndex = dataQs.findIndex(
                                                    (q, i, arr) => {
                                                      return (
                                                        stateQ >= q &&
                                                        stateQ <
                                                          (arr[i + 1] ||
                                                            stateQ + 1)
                                                      );
                                                    }
                                                  );
                                                  console.log(selectedIndex);
                                                  const selected =
                                                    selectedIndex === index2;
                                                  console.log(selected);
                                                  if (selected) {
                                                    price2 = Math.round(
                                                      parseFloat(price[1]) *
                                                        parseFloat(taka)
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          }
                                          let newSkus = this.state.skus.map(
                                            (sku1) => {
                                              console.log(price2);
                                              if (
                                                sku1.sku_id ==
                                                `${item.id}-${sku.sku_id}`
                                              ) {
                                                return {
                                                  ...sku1,
                                                  totalQuantity: e.target.value,
                                                  price: price2,
                                                };
                                              } else {
                                                return {
                                                  ...sku1,
                                                  price:
                                                    item.price_range &&
                                                    item.skus.find(
                                                      (sku) =>
                                                        `${item.id}-${
                                                          sku.sku_id
                                                        }` == sku1.sku_id
                                                    )
                                                      ? price2
                                                      : sku1.price,
                                                };
                                              }
                                            }
                                          );
                                          this.setState({
                                            skus: newSkus,
                                          });
                                        } else if (e.target.value > 100000) {
                                          alert("Can't be more than 100000");
                                        } else {
                                          alert("Can't be empty or 0");
                                        }
                                      }}
                                      style={{
                                        backgroundColor: "white",
                                        padding: "4px 5px",
                                        borderTop: "1px solid #18768f",
                                        borderBottom: "1px solid #18768f",
                                        borderLeft: "none",
                                        borderRight: "none",
                                        maxWidth: 70,
                                        minWidth: 70,
                                        fontSize: 10,
                                        textAlign: "center",
                                      }}
                                    />

                                    <div
                                      style={{
                                        padding: 4,
                                        paddingRight: 6,
                                        paddingLeft: 6,
                                        backgroundColor: "#18768f",
                                        border: "1px solid #18768f",
                                        borderTopRightRadius: 3,
                                        borderBottomRightRadius: 3,
                                        fontSize: 10,
                                        color: "white",
                                        marginLeft: 1,
                                      }}
                                    >
                                      <i
                                        className="icofont-plus"
                                        onClick={() =>
                                          this.incrementQuantity(sku, item)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "gray",
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                  }}
                                  className="col"
                                >
                                  {this.state.skus.find(
                                    (sku1) =>
                                      sku1.sku_id == `${item.id}-${sku.sku_id}`
                                  ) &&
                                    parseInt(
                                      this.state.skus.find(
                                        (sku1) =>
                                          sku1.sku_id ==
                                          `${item.id}-${sku.sku_id}`
                                      ).price
                                    )}
                                  Tk
                                </div>
                                <div
                                  style={{
                                    color: "darkorange",
                                    fontSize: 17,
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                  }}
                                  className="col"
                                >
                                  {this.state.skus.find(
                                    (sku1) =>
                                      sku1.sku_id == `${item.id}-${sku.sku_id}`
                                  ) &&
                                    parseInt(
                                      this.state.skus.find(
                                        (sku1) =>
                                          sku1.sku_id ==
                                          `${item.id}-${sku.sku_id}`
                                      ).totalQuantity *
                                        parseFloat(
                                          this.state.skus.find(
                                            (sku1) =>
                                              sku1.sku_id ==
                                              `${item.id}-${sku.sku_id}`
                                          ).price
                                        )
                                    )}
                                  Tk
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          className="d-flex flex-row"
                          style={{
                            backgroundColor: "white",
                            display: "flex",
                            borderBottom: "1px solid gainsboro",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#fff4f4",
                            }}
                          >
                            {" "}
                            <i
                              className="icofont-trash"
                              onClick={() => {
                                this.removeItemFromShop({
                                  ...item,
                                  shopId: shop.shopId,
                                });
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{ padding: "10px 20px" }}
              className="sticky-settlement-container"
            >
              <div className="d-flex flex-row settlement-container">
                <div style={{ padding: "20px 10px" }}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      style={{
                        height: 20,
                        width: 20,
                      }}
                      checked={this.state.selectAll}
                      onChange={this.onSelectAllChange}
                    />
                    <label
                      className="form-check-label"
                      style={{
                        marginTop: 3,
                        fontSize: 16,
                        fontWeight: "bold",
                        marginLeft: 8,
                      }}
                    >
                      Select all
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 3,
                    padding: "20px 0px",
                  }}
                >
                  delete
                </div>
                <div style={{ fontSize: 15, padding: "20px 0px" }}>
                  Number of Sellers :{" "}
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    {cart.length}/12
                  </span>
                </div>
                <div style={{ padding: "20px 0px" }}>
                  Good Category:{" "}
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    {goodCategory}
                  </span>{" "}
                  types
                </div>
                <div style={{ padding: "20px 0px" }}>
                  Total Quantity:{" "}
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    {totalQuantity}{" "}
                  </span>
                  Pieces
                </div>
                <div style={{ padding: "20px 0px" }}>
                  Total amount(excluding shipping charge):{" "}
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    {totalAmount}Tk{" "}
                  </span>
                </div>
                {onlySkus.length > 0 ? (
                  <button
                    type="submit"
                    className="btn btn-solid"
                    onClick={() => {
                      this.clickOnSettlement(onlySkus, totalAmount);
                    }}
                  >
                    settlement
                  </button>
                ) : (
                  <button
                    className="btn"
                    disabled
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      opacity: 0.5,
                      cursor: "not-allowed",
                    }}
                  >
                    settlement
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div
            className="container"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <div className="d-flex flex-row">
              <div className="right-detail-column" style={{ minWidth: "100%" }}>
                <div className="d-flex flex-row search-bar-row">
                  <form className="form-inline subscribe-form">
                    <div className="form-group mx-sm-3">
                      <div className="purchase-title">My purchase order</div>
                    </div>
                  </form>
                </div>
                {cart.map((shop, index) => (
                  <div style={{ marginTop: 30 }} key={index}>
                    <div className="each-order-container">
                      <div className="order-header">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>
                            <input
                              className="checkbox-round"
                              type="checkbox"
                              value=""
                              checked={this.state[shop.shopId]}
                              onChange={(e) => this.onShopChange(e, shop)}
                              style={{ height: 15, width: 15, marginTop: 8 }}
                              // onClick={() => {
                              //   this.setState({
                              //     checked: !this.state.checked,
                              //   });
                              // }}
                            />
                          </div>
                          <div className="shop-title">
                            <i
                              className="icofont-brand-alipay"
                              style={{ color: "darkred" }}
                            />
                            &nbsp;{shop.shopName}
                            &nbsp;
                          </div>
                        </div>

                        <div
                          className="more-offers"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            target="_blank"
                            href={`/collection/${shop.shopId}?shop_name=${
                              shop.shopName
                            }`}
                            style={{ color: "black", fontSize: 9 }}
                          >
                            View store
                            <i
                              className="icofont-long-arrow-right"
                              style={{ marginTop: 2 }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {shop.items.map((item, index) => (
                      <div
                        className="products-box"
                        key={index}
                        style={{ paddingLeft: 0, paddingRight: 5 }}
                      >
                        <div className="image-title-container">
                          <div className="product-image-title-row">
                            <div
                              className="product-checkbox"
                              style={{ marginTop: 2 }}
                            >
                              <input
                                className="checkbox-round"
                                type="checkbox"
                                value=""
                                checked={this.state[item.id]}
                                onChange={(e) => this.onItemChange(e, item)}
                                style={{ height: 15, width: 15, marginTop: 8 }}
                              />
                            </div>
                            <div
                              className="product-image-box"
                              style={{
                                backgroundImage: `url(${item.picture})`,
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div>
                            <div
                              className="product-title-text"
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 11,
                                paddingBottom: 0,
                              }}
                            >
                              {item.name.slice(0, 70)}...
                            </div>
                            <span
                              style={{
                                color: "#333",
                                marginLeft: 10,
                                fontSize: 11,
                              }}
                            >
                              Product Type:{item.productType}
                            </span>
                          </div>
                        </div>
                        {item.skus.map((sku, index) => (
                          <div
                            className="product-variant-row-container"
                            key={index}
                            style={{
                              background: "#f7f7f7",
                              marginTop: 10,
                              marginLeft: 5,
                            }}
                          >
                            <div className="product-image-title-row">
                              <div
                                className="product-checkbox"
                                style={{ marginTop: 2 }}
                              >
                                <input
                                  className="checkbox-round"
                                  type="checkbox"
                                  value=""
                                  checked={
                                    this.state[`${item.id}-${sku.sku_id}`]
                                  }
                                  style={{
                                    height: 15,
                                    width: 15,
                                    marginTop: 8,
                                    marginLeft: 5,
                                  }}
                                  onChange={(e) =>
                                    this.onSkuChange(e, item, sku)
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="product-image-box2"
                              style={{
                                backgroundImage: `url(${
                                  sku.image ? sku.image : item.picture
                                })`,
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                              }}
                            />
                            <div
                              className="size-color-variant-row"
                              style={{ paddingTop: 0 }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#4c4c4c",
                                    fontSize: 9,
                                  }}
                                >
                                  {sku.color && (
                                    <>
                                      <span style={{ color: "black" }}>
                                        {item.propertyNames[0]}:
                                      </span>{" "}
                                      {sku.color}
                                    </>
                                  )}{" "}
                                  <br />
                                  {sku.size && (
                                    <>
                                      <span style={{ color: "black" }}>
                                        {item.propertyNames[1]}:
                                      </span>
                                      {sku.size}`
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{
                                    color: "#d37400",
                                    fontWeight: "bold",
                                    fontSize: 9,
                                    marginTop: 5,
                                  }}
                                >
                                  {this.state.skus.find(
                                    (sku1) =>
                                      sku1.sku_id == `${item.id}-${sku.sku_id}`
                                  ) &&
                                    parseInt(
                                      this.state.skus.find(
                                        (sku1) =>
                                          sku1.sku_id ==
                                          `${item.id}-${sku.sku_id}`
                                      ).price
                                    )}
                                  Tk/quantity
                                </div>
                                <div
                                  style={{
                                    color: "#d37400",
                                    fontSize: 9,
                                    fontWeight: "bold",
                                    marginTop: 5,
                                  }}
                                >
                                  Total:{" "}
                                  {this.state.skus.find(
                                    (sku1) =>
                                      sku1.sku_id == `${item.id}-${sku.sku_id}`
                                  ) &&
                                    parseInt(
                                      this.state.skus.find(
                                        (sku1) =>
                                          sku1.sku_id ==
                                          `${item.id}-${sku.sku_id}`
                                      ).totalQuantity *
                                        parseFloat(
                                          this.state.skus.find(
                                            (sku1) =>
                                              sku1.sku_id ==
                                              `${item.id}-${sku.sku_id}`
                                          ).price
                                        )
                                    )}
                                  Tk
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div className="qty-plus-minus">
                                  <i
                                    className="icofont-minus"
                                    style={{ color: "white" }}
                                    onClick={() =>
                                      this.decrementQuantity(sku, item)
                                    }
                                  />
                                </div>
                                <input
                                  type="number"
                                  className="qty-input-box"
                                  value={
                                    this.state.skus.find(
                                      (sku1) =>
                                        sku1.sku_id ==
                                        `${item.id}-${sku.sku_id}`
                                    ) &&
                                    this.state.skus.find(
                                      (sku1) =>
                                        sku1.sku_id ==
                                        `${item.id}-${sku.sku_id}`
                                    ).totalQuantity
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value > 0 &&
                                      e.target.value < 100001
                                    ) {
                                      let price2 = sku.price;
                                      if (item.price_range) {
                                        let price_range = JSON.parse(
                                          item.price_range
                                        );
                                        if (
                                          price_range &&
                                          price_range.length > 0
                                        ) {
                                          let totalQuantity = this.getItemTotalQuantity(
                                            item,
                                            sku,
                                            this.state.skus,
                                            e.target.value
                                          );
                                          console.log(item);

                                          console.log(totalQuantity);
                                          console.log(price_range);
                                          price_range.map(
                                            (price, index2, arr) => {
                                              const stateQ = parseInt(
                                                totalQuantity
                                              );
                                              const dataQs = arr.map((p, i) =>
                                                i === 0 ? 0 : parseInt(p[0])
                                              );
                                              const selectedIndex = dataQs.findIndex(
                                                (q, i, arr) => {
                                                  return (
                                                    stateQ >= q &&
                                                    stateQ <
                                                      (arr[i + 1] || stateQ + 1)
                                                  );
                                                }
                                              );
                                              console.log(selectedIndex);
                                              const selected =
                                                selectedIndex === index2;
                                              console.log(selected);
                                              if (selected) {
                                                price2 = Math.round(
                                                  parseFloat(price[1]) *
                                                    parseFloat(taka)
                                                );
                                              }
                                            }
                                          );
                                        }
                                      }
                                      let newSkus = this.state.skus.map(
                                        (sku1) => {
                                          console.log(price2);
                                          if (
                                            sku1.sku_id ==
                                            `${item.id}-${sku.sku_id}`
                                          ) {
                                            return {
                                              ...sku1,
                                              totalQuantity: e.target.value,
                                              price: price2,
                                            };
                                          } else {
                                            return {
                                              ...sku1,
                                              price:
                                                item.price_range &&
                                                item.skus.find(
                                                  (sku) =>
                                                    `${item.id}-${
                                                      sku.sku_id
                                                    }` == sku1.sku_id
                                                )
                                                  ? price2
                                                  : sku1.price,
                                            };
                                          }
                                        }
                                      );
                                      this.setState({
                                        skus: newSkus,
                                      });
                                    } else if (e.target.value > 100000) {
                                      alert("Can't be more than 100000");
                                    } else {
                                      alert("Can't be empty or 0");
                                    }
                                  }}
                                />

                                <div className="qty-plus-minus">
                                  <i
                                    className="icofont-plus"
                                    style={{ color: "white" }}
                                    onClick={() =>
                                      this.incrementQuantity(sku, item)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div
                          style={{
                            padding: "10px 20px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginTop: 5,
                          }}
                        >
                          {" "}
                          <i
                            className="icofont-trash"
                            onClick={() => {
                              this.removeItemFromShop({
                                ...item,
                                shopId: shop.shopId,
                              });
                            }}
                            style={{ color: "#4d4d4d", cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    ))}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        backgroundColor: "white",
                        padding: "6px 20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "darkgray",
                        }}
                      >
                        <p style={{ fontSize: 10, margin: 5 }}>
                          {shop.items.length} kinds
                        </p>
                        <p style={{ fontSize: 10, marginBottom: 5 }}>
                          {" "}
                          {this.getShopTotalQuantity(onlySkus, shop)} pieces in
                          total
                        </p>
                      </div>
                      <div style={{ marginTop: 5 }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "darkorange",
                          }}
                        >
                          {this.getShopTotalTaka(onlySkus, shop)} Taka
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{ padding: "10px 0px", marginBottom: 40 }}
            className="sticky-settlement-container"
          >
            <div className="d-flex flex-row settlement-container">
              <div style={{ padding: "10px 0px" }}>
                <div>
                  <input
                    className="checkbox-round"
                    type="checkbox"
                    value=""
                    checked={this.state.selectAll}
                    style={{
                      height: 15,
                      width: 15,
                      marginTop: 1,
                      marginLeft: 5,
                    }}
                    onChange={this.onSelectAllChange}
                  />
                  <label
                    className="form-check-label"
                    style={{
                      marginTop: 3,
                      fontSize: 8,
                      fontWeight: "bold",
                    }}
                  >
                    Select all
                  </label>
                </div>
              </div>

              <div
                style={{
                  padding: "15px 0px",
                  fontSize: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 9,
                    fontWeight: "bold",
                    color: "darkorange",
                  }}
                >
                  {totalAmount}Taka
                </div>
                <div style={{ fontSize: 8, color: "darkgray" }}>
                  Total amount(excluding shipping charge)
                </div>
              </div>
              {onlySkus.length > 0 ? (
                <button
                  type="submit"
                  className="btn btn-solid"
                  style={{ fontSize: 8, padding: 5 }}
                  onClick={() => {
                    this.clickOnSettlement(onlySkus, totalAmount);
                  }}
                >
                  settlement
                </button>
              ) : (
                <button
                  className="btn"
                  disabled
                  style={{
                    backgroundColor: "gray",
                    color: "white",
                    opacity: 0.5,
                    cursor: "not-allowed",
                  }}
                >
                  settlement
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  cart: state.cartList.cart,
  currency: state.user.currency,
});

export default connect(
  mapStateToProps,
  { addToPendingOrdersRedux, removeItemFromCartRedux }
)(Cart1688);
