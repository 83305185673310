import shop from "../api/shop";
import * as types from "../constants/ActionTypes";
import store from "../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import wishlist from "../components/wishlist";
import Axios from "axios";
import {
  setProductRequest,
  getAllBookingsOfSingleUser,
  getAllShipForMeOfSingleUser,
  updateMultipleProductRequest,
  uploadPaymentRequest,
  uploadPaymentRequest2,
  uploadRefundApply,
  getCurrency,
  addCartItemTofirestore,
  removeItemFromCart,
  addToOrdersApi,
  getAllOrdersApi,
  updateOrdersApi,
  setShipForMe,
  updateShipForMe,
  updateShipmentRequest,
  uploadShipmentPaymentRequest,
  getAllD2DRates,
  getAllBanners,
  getAllCampaigns,
  getAllShipForMeList,
  uploadShipForMeList,
  updateShipForMeList,
  deleteShipForMeList,
  getSingleShipForMe,
  getAllHomeCategory,
  sendOtp,
  verifyOtp,
  getSingleOrderApi,
  getAllNotices,
  getSingleProductRequest,
} from "../firebase/firebase.utils";
export const setCurrentUser = (user) => {
  return {
    type: "SET_CURRENT_USER",
    payload: user,
  };
};

export const setReduxCart = (cartArray) => {
  return {
    type: "SET_REDUX_CART",
    payload: cartArray,
  };
};

export const setReduxWishlist = (wishlistArray) => {
  return {
    type: "SET_REDUX_WISHLIST_ARRAY",
    payload: wishlistArray,
  };
};
export const setSearchedProductsArray = (totalResults, productsArray) => {
  return {
    type: "SET_SEARCHED_PRODUCTS_ARRAY",
    payload: productsArray,
    totalResults,
  };
};
export const emptySearchedProductsArray = () => {
  return {
    type: "EMPTY_SEARCHED_PRODUCTS_ARRAY",
  };
};
export const setSearchedProductDetail = (product, route) => {
  return {
    type: "SET_SEARCHED_PRODUCT_DETAIL",
    payload: product,
    route,
  };
};

export const getAllProductsFirestore = (productsArray) => {
  return {
    type: "FETCH_ALL_PRODUCTS_FROM_FIRESTORE",
    payload: productsArray,
  };
};

export const getAllHomeCategoryRedux = () => async (dispatch) => {
  const allCampaigns = await getAllHomeCategory();
  dispatch({
    type: "GET_ALL_HOME_CATEGORIES",
    payload: allCampaigns,
  });
};
export const fetchSingleProduct = (productObj) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  payload: productObj,
});

//it seems that I should probably use this as the basis for "Cart"

// export const addToCart = (product) => (dispatch) => {
//   toast.success("Item Added to Cart");
//   dispatch(addToCartUnsafe(product));
// };
export const addToCart = (currentUser, product) => async (dispatch) => {
  const cartArray = await addCartItemTofirestore(currentUser, product);
  dispatch({
    type: types.ADD_TO_CART,
    payload: cartArray,
  });
};

export const setIntroModal = (introModal) => async (dispatch) => {
  dispatch({ type: "SET_INTRO_MODAL", payload: introModal });
};
export const addToOrdersApiRedux = (currentUser, orders) => async (
  dispatch
) => {
  const ordersArray = await addToOrdersApi(currentUser, orders);
  dispatch({
    type: "ADD_TO_ORDERS_API",
    payload: ordersArray,
  });
};

export const getSingleOrderApiRedux = (bookingId) => async (dispatch) => {
  const orderObj = await getSingleOrderApi(bookingId);
  dispatch({
    type: "GET_SINGLE_ORDER_API",
    payload: orderObj,
  });
};

export const getSingleProductRequestRedux = (bookingId) => async (dispatch) => {
  const orderObj = await getSingleProductRequest(bookingId);
  dispatch({
    type: "GET_SINGLE_PRODUCT_REQUEST",
    payload: orderObj,
  });
};

export const getSingleShipForMeRedux = (bookingId) => async (dispatch) => {
  const shipForMeObj = await getSingleShipForMe(bookingId);
  dispatch({
    type: "GET_SINGLE_SHIP_FOR_ME",
    payload: shipForMeObj,
  });
};
export const updateOrdersApiRedux = (currentUser, orders) => async (
  dispatch
) => {
  const ordersArray = await updateOrdersApi(currentUser, orders);
  dispatch({
    type: "UPDATE_ORDERS_API",
    payload: ordersArray,
  });
};
export const removeItemFromCartRedux = (currentUser, item) => async (
  dispatch
) => {
  const cartArray = await removeItemFromCart(currentUser, item);
  dispatch({
    type: "REMOVE_FROM_CART_API",
    payload: cartArray,
  });
};

export const addToPendingOrdersRedux = (pendingOrders) => async (dispatch) => {
  dispatch({
    type: "ADD_TO_PENDING_ORDERS",
    payload: pendingOrders,
  });
};

export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
  // dispatch(removeFromWishlist(product));
};

export const addToCartUnsafe = (product) => ({
  type: types.ADD_TO_CART,
  product,
});

export const removeFromCart = (product) => (dispatch) => {
  toast.error("Item Removed from Cart");
  dispatch({
    type: types.REMOVE_FROM_CART,
    product,
  });
};

export const removeCart = () => {
  return {
    type: "REMOVE_CART",
  };
};

export const incrementQty = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
};

export const decrementQty = (product) => (dispatch) => {
  toast.warn("Item Decrement Qty to Cart");

  dispatch({
    type: types.DECREMENT_QTY,
    product,
  });
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
  toast.success("Item Added to Wishlist");
  dispatch(addToWishlistUnsafe(product));
};
export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});
export const removeFromWishlist = (product_id) => (dispatch) => {
  toast.error("Item Removed from Wishlist");
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id,
  });
};

// order porducts

export const setOrderObj = (orderObj) => {
  return {
    type: "SET_ORDER_OBJECT",
    payload: orderObj,
  };
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product) => ({
  type: types.REMOVE_FROM_COMPARE,
  product,
});

// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value,
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});

export const setImgUrl = (imgUrl) => ({
  type: "SET_IMG_URL",
  payload: imgUrl,
});
export const getOrderTrackingResultRedux = (trackingNo) => async (dispatch) => {
  const resultObj = await Axios.get(
    `https://alglimited.com/api/v1/orderTracking-alg/${trackingNo}`
  );
  dispatch({ type: "GET_ORDER_TRACKING_RESULT", payload: resultObj.data });
};

export const getAllD2DRatesRedux = (freightType, country) => async (
  dispatch
) => {
  const allD2DRatesAirArray = await getAllD2DRates(freightType, country);
  dispatch({
    type: "GET_ALL_D2D_RATES",
    payload: allD2DRatesAirArray,
  });
};

export const getAllNoticesRedux = () => async (dispatch) => {
  const noticesArray = await getAllNotices();
  dispatch({ type: "GET_ALL_NOTICES", payload: noticesArray });
};

// test if its the repository

export const setBookingRequestRedux = (bookingObj) => async (dispatch) => {
  const result = await Axios.post(
    `https://alglimited.com/api/v1/alg-set-booking`,
    { ...bookingObj }
  );
  dispatch({
    type: "SET_BOOKING_REQUEST",
    payload: result.data,
  });
};

export const setProductRequestRedux = (bookingObj) => async (dispatch) => {
  const uploadedBookingObj = await setProductRequest(bookingObj);
  dispatch({
    type: "SET_PRODUCT_REQUEST",
    payload: uploadedBookingObj,
  });
};
export const setShipForMeRedux = (bookingObj) => async (dispatch) => {
  const uploadedBookingObj = await setShipForMe(bookingObj);
  dispatch({
    type: "SET_SHIP_FOR_ME",
    payload: uploadedBookingObj,
  });
};
export const updateShipForMeRedux = (bookingObj) => async (dispatch) => {
  const uploadedBookingObj = await updateShipForMe(bookingObj);
  dispatch({
    type: "UPDATE_SHIP_FOR_ME",
    payload: uploadedBookingObj,
  });
};

export const getAllBookingsOfSingleUserRedux = (userId) => async (dispatch) => {
  const bookingsArray = await getAllBookingsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_BOOKINGS_OF_SINGLE_USER", payload: bookingsArray });
};
export const getAllShipForMeOfSingleUserRedux = (userId) => async (
  dispatch
) => {
  const bookingsArray = await getAllShipForMeOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_SHIP_FOR_ME_OF_SINGLE_USER",
    payload: bookingsArray,
  });
};
export const updateShipmentRequestRedux = (requestObj) => async (dispatch) => {
  const updatedRequest = await updateShipmentRequest(requestObj);
  dispatch({ type: "UPDATE_SHIPMENT_REQUEST", payload: updatedRequest });
};
export const updateShipForMeListRedux = (shipForMeObj) => async (dispatch) => {
  const updatedShipForMe = await updateShipForMeList(shipForMeObj);
  dispatch({ type: "UPDATE_SHIP_FOR_ME_LIST", payload: updatedShipForMe });
};
export const deleteShipForMeListRedux = (shipForMeObj) => async (dispatch) => {
  await deleteShipForMeList(shipForMeObj);
  dispatch({ type: "DELETE_SHIP_FOR_ME_LIST", payload: shipForMeObj });
};
export const getAllOrdersApiRedux = (userId) => async (dispatch) => {
  const ordersApiArray = await getAllOrdersApi(userId);
  dispatch({
    type: "GET_ALL_ORDERS_API_OF_SINGLE_USER",
    payload: ordersApiArray,
  });
};
export const getAllBannersRedux = () => async (dispatch) => {
  const banners = await getAllBanners();
  dispatch({
    type: "GET_ALL_BANNERS",
    payload: banners,
  });
};
export const getAllCampaignsRedux = () => async (dispatch) => {
  const camapaigns = await getAllCampaigns();
  dispatch({
    type: "GET_ALL_CAMPAIGNS",
    payload: camapaigns,
  });
};
export const getAllShipForMeListRedux = (userId) => async (dispatch) => {
  const shipForMesArray = await getAllShipForMeList(userId);
  dispatch({
    type: "GET_ALL_SHIP_FOR_ME_LIST",
    payload: shipForMesArray,
  });
};
export const getCurrencyRedux = () => async (dispatch) => {
  const currency = await getCurrency();
  dispatch({ type: "GET_CURRENCY_REDUX", payload: currency });
};

export const uploadPaymentRequestRedux = (paymentObject) => async (
  dispatch
) => {
  const paymentObj = await uploadPaymentRequest(paymentObject);
  dispatch({
    type: "UPDATE_BOOKINGS_OF_SINGLE_USER",
    payload: paymentObj,
  });
};
export const uploadPaymentRequestRedux2 = (paymentObject) => async (
  dispatch
) => {
  const paymentObj = await uploadPaymentRequest2(paymentObject);
  dispatch({
    type: "UPDATE_BOOKINGS_OF_SINGLE_USER",
    payload: paymentObj,
  });
};
export const uploadRefundApplyRedux = (refundObject) => async (dispatch) => {
  const refund = await uploadRefundApply(refundObject);
  dispatch({
    type: refundObject.productRequest
      ? "UPLOAD_REFUND_APPLY_PRODUCT_REQUEST"
      : "UPLOAD_REFUND_APPLY",
    payload: refund,
  });
};
export const uploadShipForMeListRedux = (shipForMeObj) => async (dispatch) => {
  const shipObj = await uploadShipForMeList(shipForMeObj);
  dispatch({
    type: "UPLOAD_SHIP_FOR_ME_LIST",
    payload: shipObj,
  });
};
export const uploadShipmentPaymentRequestRedux = (paymentObject) => async (
  dispatch
) => {
  const paymentObj = await uploadShipmentPaymentRequest(paymentObject);
  dispatch({
    type: "UPDATE_SHIPMENT_PAYMENT_REQUEST",
    payload: paymentObj.productRequestArray[0],
  });
};

export const sendOtpRedux = (number) => async (dispatch) => {
  dispatch({ type: "SET_NUMBER", payload: number });
};

export const verifyOtpRedux = (number, otp) => async (dispatch) => {
  const userObj = await verifyOtp(number, otp);
  dispatch({ type: "SET_CURRENT_USER", payload: userObj });
};
